import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchLoadingStatus, editSubAdminAPI } from '../../API/Action/globalAction';
import Loader from '../Loader/Loader';
import { useNavigate, useLocation } from "react-router-dom";
import AddAdmin from '../Admin User Management/AddAdmin';

// Added by Parth Roka
function EditAdmin() {

    let accessDataByAdminIdReg = useSelector(
        (state) => state.globalReducer.accessDataByAdminIdReg
      );
      useEffect(() => {
        if (accessDataByAdminIdReg) {
          if (accessDataByAdminIdReg.errorcode == 0) {
            
            let alldata = accessDataByAdminIdReg.data
            if (alldata.adminUserManagementEdit == true ) {
              
            } else {
              history("/Alltrip")
            }
          }
        }
      }, [accessDataByAdminIdReg]);

    const { state } = useLocation();
    let history = useNavigate();

    useEffect(() => {
        dispatch(dispatchLoadingStatus(false))

    }, [])

    const dispatch = useDispatch({});
    let test1 = state ? state.accessData : "";
    for (let key in test1) {
        if (test1.hasOwnProperty(key)) {
            let value = test1[key];
            if (Array.isArray(value)) {
                let capitalizedArray = value.map(str => str.charAt(0).toUpperCase() + str.slice(1));
                test1[key] = capitalizedArray;
            }
        }
    }

  return (
        <>
            <Loader />
            <AddAdmin state={state}/>
        </>
    )
}


export default EditAdmin;