import React from "react";
import { useSelector } from 'react-redux';

// Added by Parth Roka
function Loader() {

    const Loding = useSelector(state => state.LoadingStatusReducer.loading);
    return (
        Loding &&
        <div className="overlay-loader">
            <div className="overlay__inner">
                <div className="overlay__content">
                                 <div className="atbd-spin-dots spin-lg">
                     <span className="spin-dot badge-dot dot-primary"></span>
                     <span className="spin-dot badge-dot dot-primary"></span>
                     <span className="spin-dot badge-dot dot-primary"></span>
                     <span className="spin-dot badge-dot dot-primary"></span>
                 </div>
                </div>
            </div>
        </div>
    );
}


export default Loader;