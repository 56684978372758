import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import MainDashboard from "../Main Dashboard/MainDashboard";
import moment from 'moment';
import {
  accessDataByAdminIdAPI,
  dispatchLoadingStatus, accommodationListAPI, accommodationHotelListAPI, TripDetailsId_API
} from '../../API/Action/globalAction';
import swal from 'sweetalert';
import ProfileImg from "../../assets/Images/black22.svg"

// Added by Moinuddin Mansuri
function TripDetails() {
  const { state } = useLocation();

  const [accommodationList, setAccomadationList] = useState();
  const [buses, setBuses] = useState();
  const [plane, setPlane] = useState();
  const [train, setTrain] = useState();
  const [hotel, setHotel] = useState();

  const dispatch = useDispatch({});
  let history = useNavigate();

  let toggleReducer = useSelector((state) => state.globalReducer.toggle);


  // Api Call (Moinuddin Mansuri)
  useEffect(() => {

    if (state) {
      let formData2 = new FormData();
      let token = localStorage.getItem("token");
      formData2.append("user_id", state ? state.user_id : "");
      formData2.append("trip_id", state ? state._id : "");
      formData2.append("token", token);

      dispatch(accommodationListAPI(formData2));
      dispatch(accommodationHotelListAPI(formData2));
      dispatch(TripDetailsId_API(formData2));
    }
  }, [state]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    let token = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("token", token);
    dispatch(dispatchLoadingStatus(true));
    dispatch(accessDataByAdminIdAPI(formData));

  }, []);


  let accessDataByAdminIdReg = useSelector(
    (state) => state.globalReducer.accessDataByAdminIdReg
  );

  let accommodationListReg = useSelector(
    (state) => state.globalReducer.accommodationListReg
  );
  let accommodationHotelListReg = useSelector(
    (state) => state.globalReducer.accommodationHotelListReg
  );

  let tripDetailsReg = useSelector(
    (state) => state.globalReducer.tripDetailsReg
  );

  useEffect(() => {
    if (accommodationListReg) {
      if (accommodationListReg.errorcode == 0) {
        setAccomadationList(accommodationListReg ? accommodationListReg.data : [])

      }
    }
  }, [accommodationListReg]);
  useEffect(() => {
    if (accommodationHotelListReg) {
      if (accommodationHotelListReg.errorcode == 0) {
        setHotel(accommodationHotelListReg ? accommodationHotelListReg.data.hotel : [])

      }
    }
  }, [accommodationHotelListReg]);

  const [tripDetails, setTripDetails] = useState([]);
  useEffect(() => {
    if (tripDetailsReg) {
      if (tripDetailsReg.errorcode == 0) {
        setTripDetails(tripDetailsReg ? tripDetailsReg.data[0] : [])

      }
    }
  }, [tripDetailsReg]);

  useEffect(() => {
    if (accommodationList) {
      setBuses(accommodationList ? accommodationList.bus : [])
      setPlane(accommodationList ? accommodationList.flight : [])
      setTrain(accommodationList ? accommodationList.train : [])
    }

  }, [accommodationList]);
  const [access, setAccess] = useState();

  useEffect(() => {
    if (accessDataByAdminIdReg) {
      if (accessDataByAdminIdReg.errorcode == 0) {
        setAccess(
          accessDataByAdminIdReg
            ? accessDataByAdminIdReg.data
            : accessDataByAdminIdReg.data
        );
        let alldata = accessDataByAdminIdReg.data
        if (alldata.tripManage == true && alldata.tripRead == true) {

        } else {
          history("/Alltrip")
        }
      }
    }
  }, [accessDataByAdminIdReg]);

  let isUserlistReadTrue = access ? access.userRead : "";
  const handleMember = (i) => {
    history("/Userdetails", { state: i });
  };

  const [activeIndexes6, setActiveIndexes6] = useState([]);

  const handleClick6 = (index) => {
    if (index == 0) {
      setActiveIndexesItiF(true);
    }
    else {
      setActiveIndexesItiF(false);

    }
    if (activeIndexes6.includes(index)) {
      setActiveIndexes6(activeIndexes6.filter((i) => i !== index));

    } else {
      setActiveIndexes6([...activeIndexes6, index]);
    }
  };
  const getClassNames6 = (index) => {
    return activeIndexes6.includes(index)
      ? "atbd-collapse-item__header active"
      : "atbd-collapse-item__header";
  };

  const [activeIndexesIti, setActiveIndexesIti] = useState([0]);
  const [activeIndexesItiF, setActiveIndexesItiF] = useState(false);

  const handleClickIti = (index) => {

    if (activeIndexesIti.includes(index)) {
      setActiveIndexesIti(activeIndexesIti.filter((i) => i !== index));
    } else {
      setActiveIndexesIti([...activeIndexesIti, index]);
    }
  };

  const getClassNamesIti = (index) => {
    return activeIndexesIti.includes(index)
      ? "atbd-collapse-item__header active"
      : "atbd-collapse-item__header";
  };


  const [activeIndexesIti2, setActiveIndexesIti2] = useState(true);

  const handleClickIti2 = (index) => {
    setActiveIndexesIti2(!activeIndexesIti2)
  };

  const [activeIndexesIti3, setActiveIndexesIti3] = useState(false);

  const handleClickIti3 = (index) => {
    setActiveIndexesIti3(!activeIndexesIti3)
  };

  const [activeIndexesIti4, setActiveIndexesIti4] = useState(false);

  const handleClickIti4 = (index) => {
    setActiveIndexesIti4(!activeIndexesIti4)
  };

  const [activeIndexesIti5, setActiveIndexesIti5] = useState(true);

  const handleClickIti5 = (index) => {
    setActiveIndexesIti5(!activeIndexesIti5)
  };

  let maping = tripDetails ? tripDetails.itinerary ? tripDetails.itinerary : [] : [];
  const data = tripDetails ? maping : [];

  const groupedItineraries = {};
  for (const obj of data) {
    const activityDate = new Date(obj.activityDate);
    const dateKey = activityDate.toISOString().split('T')[0];
    if (!groupedItineraries[dateKey]) {
      groupedItineraries[dateKey] = [];
    }
    groupedItineraries[dateKey].push(obj);
  }

  const response = Object.keys(groupedItineraries).map(dateKey => ({
    date: dateKey,
    itinerary_list: groupedItineraries[dateKey]
  }));

  response.sort((a, b) => new Date(b.date) - new Date(a.date));

  function formatDate(inputDate) {
    const daysOfWeek = [
      'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ];

    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const date = new Date(inputDate);
    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayOfWeek}, ${month} ${day}, ${year}`;
  }

  return (
    <>
      <div
        className={toggleReducer == false ? "contents expanded" : "contents"}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-main application-ui mb-30">
                <div className="breadcrumb-action d-flex">
                  <div className="d-flex align-items-center user-member__title mr-sm-25 mr-0">
                    <h4 className="text-capitalize fw-500 breadcrumb-title">
                      Trip Details
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="projects-tab-content mb-30">
            <div className="row">
              <div className="col-lg-4 mb-25">
                <div className="slider-card-main row">
                  <div className="car ">
                    <div className="card ">
                      <div className="card-body">
                        <div className="card-content">
                          <div className="card-title ">
                            {tripDetails ? tripDetails.name : ""}
                          </div>
                        </div>
                        <img src={tripDetails ? tripDetails.coverPic : ""} />
                        <div id="" className="middle">
                          <div className="text">Slide 1</div>
                        </div>

                        <div className="card-content">
                          <div className="card-title font-weight-bold">
                            {tripDetails ? tripDetails.tripCreatedBy : ""}
                          </div>
                          <div>
                            <i className="fa fa-crown color-gold"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-xxl-12 col-lg-12 mb-25">
                    <div className="card itinerary-card">
                      <div className="card-header py-sm-20 py-3  px-sm-25 px-3 ">
                        <h6>Itinerary</h6>

                        <h6>{tripDetails ? tripDetails.startDate : ""}</h6>
                        <h6>
                          {tripDetails
                            ? tripDetails.startDate && tripDetails.endDate
                              ? "to"
                              : ""
                            : ""}
                        </h6>
                        <h6>{tripDetails ? tripDetails.endDate : ""}</h6>
                      </div>

                      <div className="card-body overflow-x-auto py-20 pl-25 pr-sm-20 pr-30">
                        <div className="timeline-box--3 timeline-vertical left-middle basic-timeline scroll-timeline">
                          <ul className="timeline timeline-new">
                            <div className="card-body">
                              <div className="mb-30">
                                <div className="atbd-collapse">
                                  {tripDetails
                                    ? response?.map((i, index) => (
                                      <div className="atbd-collapse-item" key={index}>
                                        <div
                                          className={getClassNamesIti(index)}
                                        // className={arrow ? "atbd-collapse-item__header" : " atbd-collapse-item__header active"}
                                        >
                                          <a
                                            onClick={() => handleClickIti(index)}
                                            className="item-link"
                                            data-toggle="collapse"
                                            data-target={`#collapse-body-${index + 1}`}
                                            aria-expanded="true"
                                            aria-controls={`collapse-body-${index + 1}`}
                                          >
                                            <i className="la la-angle-right"></i>
                                            <h3>{i ? formatDate(i.date) : ""}</h3>
                                          </a>
                                        </div>
                                        <div
                                          id={`collapse-body-${index + 1}`}
                                          className={index == 0 ? `collapse atbd-collapse-item__body show` : `collapse atbd-collapse-item__body`}
                                        >
                                          <div className="collapse-body-text pl-50">
                                            <div className="row">
                                              {i.itinerary_list ? i.itinerary_list.map((u) => (
                                                <ul className="timeline timeline-new">
                                                  <li className="timeline-inverted">
                                                    <div className="timeline-single__buble">
                                                      <div className="timeline-single__buble--svg  bg-opacity-primary">
                                                        <img src={u.categoryImg} className="text-primary" alt="img" />
                                                      </div>
                                                    </div>
                                                    <div className="timeline-single pl-5">
                                                      <div className="timeline-single__days">
                                                        <span>{u.name}</span>
                                                      </div>
                                                      <div className="timeline-single__content">
                                                        <p className="m-0">{u.categoryName}</p>
                                                        <span>{u.location}</span>
                                                      </div>
                                                      <div className="timeline-single__content">
                                                        <p className="m-0">{u.activityTime}</p>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </ul>
                                              )) : ""}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                    : ""}
                                  {tripDetails ? (
                                    tripDetails?.itinerary?.length > 0 ? null : (
                                      <div style={{ textAlign: "center", textAlign: "center", fontWeight: "500", color: "#000", }}>
                                        <p className="my-2">No Itinerary Created</p>
                                      </div>
                                    )
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-12 mb-25">
                <div className="card user-list-card">
                  <div className="card-header d-flex justify-content-between align-items-center py-10  px-sm-25 px-3">
                    <h6 className="fw-500 ">Members</h6>
                  </div>
                  <div className="timeline-box--3 timeline-vertical left-middle basic-timeline scroll-timeline">
                    {tripDetails
                      ? tripDetails?.members?.map((i) => (
                        <div className="card-body pb-0">
                          <div className="d-flex align-items-center ">

                            <div className="avatar avatar-circle ml-0">
                              <img
                                style={{ cursor: "pointer", borderRadius: "50%", }}
                                src={
                                  i.memberProfilePicUrl
                                    ? i.memberProfilePicUrl
                                    : ProfileImg
                                }
                                className="wh-46"
                                alt="img"

                                onClick={() => {
                                  if (isUserlistReadTrue == true) {
                                    handleMember(i)
                                  } else {
                                    swal({
                                      text: "you don't have permission",
                                      icon: "warning",
                                      dangerMode: true,
                                    })
                                  }
                                }}
                              />
                              {i.role == 1 ?
                                <div className="badge-direction-bottom">
                                  <span className=" text-warning">
                                    <i className="fa fa-crown"></i>
                                  </span>
                                </div> : ""
                              }
                            </div>
                            <div>
                              <p className="fs-14 fw-600 color-dark mb-0">
                                {i.memberName ? i.memberName : "No Data Found"}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))
                      : ""}</div>
                </div>
              </div>

              <div className="col-xxl-8 col-lg-8 mb-25">
                <div className="card ">
                  <div className="card-header py-20  px-sm-25 px-3 ">
                    <h6>Polls</h6>
                  </div>

                  <div className="card-body">
                    <div className="mb-30">
                      <div className="atbd-collapse">

                        {tripDetails
                          ? tripDetails?.polls?.map((i, index) => (
                            <div className="atbd-collapse-item" key={index}>
                              <div
                                className={getClassNames6(index)}
                              >
                                <a
                                  onClick={() => handleClick6(index)}
                                  className="item-link"
                                  data-toggle="collapse"
                                  data-target={`#collapse-body6-${index + 1}`}
                                  aria-expanded="true"
                                  aria-controls={`collapse-body6-${index + 1}`}
                                >
                                  <i className=" la la-angle-right"></i>
                                  <h3>{i.categoryName}</h3>
                                </a>
                              </div>
                              <div
                                id={`collapse-body6-${index + 1}`}
                                className={index == 0 ? `collapse atbd-collapse-item__body show` : `collapse atbd-collapse-item__body`}
                              >
                                <div className="collapse-body-text">

                                  <div className="row">
                                    {i.answer_options ? i.answer_options.map((u) => (
                                      <div className="col">
                                        <h5 className="fs-14 fw-600 color-dark mb-0">
                                          {u.option}
                                        </h5>
                                        <div className="user-group-progress-bar">
                                          <div className="progress-wrap d-flex align-items-center mb-0">
                                            <div className="progress">
                                              <div
                                                className="progress-bar bg-primary"
                                                role="progressbar"
                                                style={{ width: u.votes / i.responses * 100 + "%" }}
                                                aria-valuenow="50"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>

                                            <span className="progress-percentage">
                                              {i.responses == 0
                                                ? 0
                                                : (u.votes / i.responses) * 100}%
                                            </span>
                                          </div>

                                          <p className="color-light fs-12 mb-20">
                                            {u.votes} /  {i.responses} votes
                                          </p>
                                        </div>
                                      </div>
                                    )) : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                          : ""}
                        {tripDetails ? (
                          tripDetails?.polls?.length > 0 ? null : (
                            <div style={{ textAlign: "center", textAlign: "center", fontWeight: "500", color: "#000", }}>
                              <p className="my-2">No polls details</p>
                            </div>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="row">
                  <div className="col-xxl-12 col-lg-12 mb-25">
                    <div className="card itinerary-card">
                      <div className="card-header py-sm-20 py-3  px-sm-25 px-3 ">
                        <h6>Transportation</h6>
                      </div>

                      <div className="card-body overflow-x-auto py-20 pl-25 pr-sm-10 pr-30">
                        <div className="timeline-box--3 timeline-vertical left-middle basic-timeline scroll-timeline">
                          <ul className="timeline">
                            <div className="card-body">
                              <div className="mb-30">
                                <div className="atbd-collapse">
                                  <div className="atbd-collapse-item" >
                                    <div
                                      className={activeIndexesIti4 == true ? "atbd-collapse-item__header active" : "atbd-collapse-item__header"}
                                    >
                                      <a
                                        onClick={() => handleClickIti4()}
                                        className={activeIndexesIti4 == true ? "item-link" : "item-link collapsed"}
                                        data-toggle="collapse"
                                        aria-expanded="true"
                                      >
                                        <i className={`la ${activeIndexesIti4 == true ? "la-angle-down" : "la-angle-up"}`}></i>
                                        <h3>Flight</h3>
                                      </a>
                                    </div>
                                    <div
                                      className={activeIndexesIti4 == false ? `collapse atbd-collapse-item__body show` : `collapse atbd-collapse-item__body`}
                                    >
                                      <div className="collapse-body-text ">
                                        <div className="row">
                                          <ul className="timeline p-2">
                                            {plane
                                              && plane.length > 0 ? plane.map((i, index) => (
                                                <li className="timeline-inverted">
                                                  <div className="tick-details-main">
                                                    <div className="tick-details-flex">
                                                      <h5>{i.nameAirline}</h5>
                                                      <p>{moment(i.depatureDate).format("MMMM D, YYYY")}</p>
                                                    </div>
                                                    <div class="tick-details-flex">
                                                      <div>
                                                        <p>Departure</p>
                                                        <h5>{i.depatureTime}</h5>
                                                        <p>{i.depatureAirportCode} </p>
                                                        <p>{i.depatureCity} </p>
                                                      </div>
                                                      <div class="tick-icons">
                                                        <div className="left-tick-icon">
                                                        </div>
                                                        <div className="tick-icon-line">
                                                          <i className="fa fa-plane"></i>
                                                        </div>
                                                        <div className="right-tick-icon">
                                                        </div>
                                                      </div>
                                                      <div className="tick-right">
                                                        <p>Arrival</p>
                                                        <h5>{i.arrivalTime}</h5>
                                                        <p>{i.arrivalAirportCode} </p>
                                                        <p>{i.arrivalCity} </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              ))
                                              : <div style={{ textAlign: "center", textAlign: "center", fontWeight: "500", color: "#000", }}>
                                                <p className="my-2">No flight details</p>
                                              </div>}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="atbd-collapse">
                                  <div className="atbd-collapse-item" >
                                    <div
                                      className={activeIndexesIti2 == true ? "atbd-collapse-item__header active" : "atbd-collapse-item__header"}
                                    >
                                      <a
                                        onClick={() => handleClickIti2()}
                                        className="item-link"
                                        data-toggle="collapse"
                                        aria-expanded="true"
                                      >
                                        <i className={`la ${activeIndexesIti2 == true ? "la-angle-down" : "la-angle-up"}`}></i>
                                        <h3>Bus</h3>
                                      </a>
                                    </div>
                                    <div
                                      className={activeIndexesIti2 == false ? `collapse atbd-collapse-item__body show` : `collapse atbd-collapse-item__body`}
                                    >
                                      <div className="collapse-body-text ">
                                        <div className="row">
                                          <ul className="timeline p-2">
                                            {buses
                                              && buses.length > 0 ? buses.map((i, index) => (
                                                <li className="timeline-inverted">
                                                  <div className="tick-details-main">
                                                    <div className="tick-details-flex">
                                                      <p></p>
                                                      <p>{moment(i.depatureDate).format("MMMM D, YYYY")}</p>
                                                    </div>
                                                    <div class="tick-details-flex">
                                                      <div>
                                                        <p>Departure</p>
                                                        <h5>{i.depatureTime}</h5>
                                                        <p>{i.depatureLocation} </p>
                                                      </div>
                                                      <div class="tick-icons">
                                                        <div className="left-tick-icon">

                                                        </div>
                                                        <div className="tick-icon-line">
                                                          <i className="fa fa-bus"></i>
                                                        </div>
                                                        <div className="right-tick-icon">
                                                        </div>
                                                      </div>
                                                      <div className="tick-right">
                                                        <p>Arrival</p>
                                                        <h5>{i.arrivalTime}</h5>
                                                        <p>{i.arrivalLocation} </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              ))
                                              : <div style={{ textAlign: "center", textAlign: "center", fontWeight: "500", color: "#000", }}>
                                                <p className="my-2">No bus details</p>
                                              </div>}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="atbd-collapse">
                                  <div className="atbd-collapse-item" >
                                    <div
                                      className={activeIndexesIti5 == true ? "atbd-collapse-item__header active" : "atbd-collapse-item__header"}
                                    >
                                      <a
                                        onClick={() => handleClickIti5()}
                                        className={activeIndexesIti5 == true ? "item-link" : "item-link collapsed"}
                                        data-toggle="collapse"
                                        aria-expanded="true"
                                      >
                                        <i className={`la ${activeIndexesIti5 ? "la-angle-down" : "la-angle-up"}`}></i>
                                        <h3>Train</h3>
                                      </a>
                                    </div>
                                    <div
                                      className={activeIndexesIti5 == false ? `collapse atbd-collapse-item__body show` : `collapse atbd-collapse-item__body`}
                                    >
                                      <div className="collapse-body-text ">
                                        <div className="row">
                                          <ul className="timeline p-2">
                                            {train
                                              && train.length ? train.map((i, index) => (
                                                <li className="timeline-inverted">
                                                  <div className="tick-details-main">
                                                    <div className="tick-details-flex">
                                                      <p>{i.airline}</p>
                                                      <p>{moment(i.depatureDate).format("MMMM D, YYYY")}</p>

                                                    </div>
                                                    <div class="tick-details-flex">
                                                      <div>
                                                        <p>Departure</p>
                                                        <h5>{i.depatureTime}</h5>
                                                        <p>{i.depatureAirportCode} </p>
                                                        <p>{i.depatureLocation} </p>

                                                      </div>
                                                      <div class="tick-icons">
                                                        <div className="left-tick-icon">

                                                        </div>
                                                        <div className="tick-icon-line">
                                                          <i className="fa fa-train"></i>
                                                        </div>
                                                        <div className="right-tick-icon">

                                                        </div>
                                                      </div>
                                                      <div className="tick-right">
                                                        <p>Arrival</p>
                                                        <h5>{i.arrivalTime}</h5>
                                                        <p>{i.arrivalAirportCode} </p>
                                                        <p>{i.arrivalLocation} </p>

                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              ))
                                              : <div style={{ textAlign: "center", textAlign: "center", fontWeight: "500", color: "#000", }}>
                                                <p className="my-2">No train details</p>
                                              </div>}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-xxl-12 col-lg-12 mb-25">
                    <div className="card itinerary-card">
                      <div className="card-header py-sm-20 py-3  px-sm-25 px-3 ">
                        <h6> Hotel</h6>
                      </div>
                      <div className="card-body overflow-x-auto py-20 pl-25 pr-sm-20 pr-30">
                        <div className="timeline-box--3 timeline-vertical left-middle basic-timeline scroll-timeline">
                          <ul className="timeline timeline-new">

                            <div className="card-body">
                              <div className="mb-30">
                                <div className="atbd-collapse">
                                  <div className="atbd-collapse-item" >
                                    <div
                                      className={activeIndexesIti3 == true ? "atbd-collapse-item__header active" : "atbd-collapse-item__header"}
                                    >
                                      <a
                                        onClick={() => handleClickIti3()}
                                        className={activeIndexesIti3 == true ? "item-link" : "item-link collapsed"}
                                        data-toggle="collapse"
                                        aria-expanded="true"
                                      >
                                        <i className={`la ${activeIndexesIti3 == true ? "la-angle-down" : "la-angle-up"}`}></i>
                                        <h3>Hotel</h3>
                                      </a>
                                    </div>
                                    <div
                                      className={activeIndexesIti3 == false ? `collapse atbd-collapse-item__body show` : `collapse atbd-collapse-item__body`}
                                    >
                                      <div className="collapse-body-text pl-50">
                                        <div className="row">
                                          <ul className="timeline p-2">
                                            {hotel
                                              && hotel.length > 0 ? hotel.map((i, index) => (
                                                <li className="timeline-inverted">
                                                  <div className="tick-details-main hotel-details-main">
                                                    <div className="">
                                                      <p>{i.name}</p>
                                                      <p>{i.address}</p>
                                                    </div>
                                                    <div class="tick-details-flex">
                                                      <div>
                                                        <p>Check IN</p>
                                                        <h5>{moment(i.checkInDate).format("MMMM D, YYYY")} </h5>
                                                        <p>{i.checkInTime}</p>
                                                      </div>
                                                      <div className="tick-right">
                                                        <p>Check OUT</p>
                                                        <h5>{moment(i.checkOutDate).format("MMMM D, YYYY")} </h5>
                                                        <p>{i.checkOutTime}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              ))
                                              : <div style={{ textAlign: "center", textAlign: "center", fontWeight: "500", color: "#000", }}>
                                                <p className="my-2">No hotel details</p>
                                              </div>}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <MainDashboard />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default TripDetails;
