// QA API
export const baseURL = process.env.REACT_APP_baseURL

// export const baseURL = process.env.REACT_APP_API_URL;

// Server API
// export const baseURL = "https://qaapi.ourtravelapp.com/api/";

export const isMainProject = true;

export const saveInquiry_URL = "front/saveInquiry";
export const saveInquiryReg = "saveInquiryReg";

export const deleteUser_URL = "/admin/deleteUser";
export const deleteUserReg = "deleteUserReg";

export const toggle = "toggle";

export const IS_LOADING = "IS_LOADING";

export const AdminLogin_URL = "admin/sendOtpInEmail";
export const AdminLoginReg = "AdminLoginReg";

export const Otp_URL = "admin/OtpVerifyForEmail";
export const OtpReg = "OtpReg";

export const usersList_URL = "admin/usersList";
export const usersListReg = "usersListReg";

export const UpcomingTrips_URL = "admin/upcomingTripsList";
export const UpcomingTripReg = "UpcomingTripReg";

export const CurrentTrips_URL = "admin/currentTripsList";
export const CurrentTripsReg = "CurrentTripsReg";

export const CompletedTrips_URL = "admin/completedTripsList";
export const CompletedTripsReg = "CompletedTripsReg";

export const UndefinedTrips_URL = "admin/undefinedTripsList";
export const UndefinedTripsReg = "UndefinedTripsReg";

export const TripListVariousCountData_URL = "admin/tripListVariousCountData";
export const TripListVariousCountDataReg = "TripListVariousCountDataReg";

export const subAdminListExceptLoggedInAdmin_URL = "admin/subAdminListExceptLoggedInAdmin";
export const subAdminListExceptLoggedInAdminReg = "subAdminListExceptLoggedInAdminReg";

export const addSubAdmin_URL = "admin/addSubAdmin";
export const addSubAdminReg = "addSubAdminReg";


export const editSubAdmin_URL = "admin/editSubAdmin";
export const editSubAdminReg = "editSubAdminReg";

export const deleteSubAdmin_URL = "admin/deleteSubAdmin";
export const deleteSubAdminReg = "deleteSubAdminReg";

export const accessDataByAdminId_URL = "admin/accessDataByAdminId";
export const accessDataByAdminIdReg = "accessDataByAdminIdReg";

export const AdminStatus_URL = "admin/changeAdminStatus";
export const AdminStatusReg = "AdminStatusReg";

export const UserStatus_URL = "admin/changeUserStatus";
export const UserStatusReg = "UserStatusReg"

export const TripDataByUserId_URL = "admin/adminTripDataByUserId";
export const TripDataByUserIdReg = "tripDataByUserIdReg";

export const addCategory_URL = "admin/addCategory";
export const addCategoryReg = "addCategoryReg"

export const editCategory_URL = "admin/editCategory";
export const editCategoryReg = "editCategoryReg"

export const listAllCategory_URL = "admin/listAllCategory";
export const listAllCategoryReg = "listAllCategoryReg"

export const changeCategoryStatus_URL = "admin/changeCategoryStatus";
export const changeCategoryStatusReg = "changeCategoryStatusReg";


export const AllInquiry_URL = "admin/listAllInquiry";
export const AllInquiryReg = "AllInquiryReg";


export const InquiryExcel_URL = "admin/InquiryExcel";
export const InquiryExcelReg = "InquiryExcelReg";


export const deleteCategory_URL = "admin/deleteCategory";
export const deleteCategoryReg = "deleteCategoryReg";


export const DownloadUsersList_URL = "admin/DownloadUsersList";
export const DownloadUsersListReg = "DownloadUsersListReg";


export const accommodationList_URL = "/accommodationList";
export const accommodationListReg = "accommodationListReg";

export const accommodationHotelList_URL = "/accommodationHotelList";
export const accommodationHotelListReg = "accommodationHotelListReg";


export const tripDetailId = "/admin/tripListByTripId";
export const tripDetailsReg = "tripDetailsReg";





