import React, { useEffect, useState, useRef } from "react";
import MainDashboard from "../Main Dashboard/MainDashboard";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import {
  dispatchLoadingStatus,
  subAdminListExceptLoggedInAdminAPI,
  deleteSubAdminAPI,
  AdminStatusAPI,
} from '../../API/Action/globalAction';
import Loader from '../Loader/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import swal from "sweetalert";
import ProfileImg from "../../assets/Images/black22.jpg"

// Added by Parth Roka
function AdminUserManagment() {
  const [order, setOrder] = useState("ASC");

  // for Sorting (Parth Roka)
  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...allData22].sort((a, b) =>
        (a[col]?.toLowerCase() || "") > (b[col]?.toLowerCase() || "") ? 1 : -1
      );
      setAllData22(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...allData22].sort((a, b) =>
        (a[col]?.toLowerCase() || "") < (b[col]?.toLowerCase() || "") ? 1 : -1
      );
      setAllData22(sorted);
      setOrder("ASC");
    }
  };
  let history = useNavigate();
  const startdatePickerRef = useRef(null);
  const enddatePickerRef = useRef(null);

  let toggleReducer = useSelector((state) => state.globalReducer.toggle);

  let accessDataByAdminIdReg = useSelector(
    (state) => state.globalReducer.accessDataByAdminIdReg
  );

  const [access, setAccess] = useState();

  useEffect(() => {
    if (accessDataByAdminIdReg) {
      if (accessDataByAdminIdReg.errorcode == 0) {
        setAccess(
          accessDataByAdminIdReg
            ? accessDataByAdminIdReg.data
            : accessDataByAdminIdReg.data
        );
        let alldata = accessDataByAdminIdReg.data
        if (alldata.adminUserManagementRead == true && alldata.adminUserManagementManage) {

        } else {
          history("/Alltrip")
        }

      }
    }
  }, [accessDataByAdminIdReg]);
  let isAdminUserCreateTrue = access ? access.adminUserManagementCreate : "";
  let isAdminUserEditTrue = access ? access.adminUserManagementEdit : "";
  let isAdminUserDeleteTrue = access ? access.adminUserManagementDelete : "";

  const [allData22, setAllData22] = useState();
  const dispatch = useDispatch({});

  const [page, setpage] = useState(1);
  const [indexCh, setIndexCh] = useState(0);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("token", token);
    dispatch(dispatchLoadingStatus(true));
    dispatch(subAdminListExceptLoggedInAdminAPI(formData));
  }, []);

  let subAdminListExceptLoggedInAdminReg = useSelector(
    (state) => state.globalReducer.subAdminListExceptLoggedInAdminReg
  );

  useEffect(() => {
    if (subAdminListExceptLoggedInAdminReg) {
      if (subAdminListExceptLoggedInAdminReg.errorcode == 0) {
        // window.scrollTo(0, 0);
        setAllData22(
          subAdminListExceptLoggedInAdminReg
            ? subAdminListExceptLoggedInAdminReg.data
            : subAdminListExceptLoggedInAdminReg.data
        );
      } else {
        setAllData22([]);
      }
    }
  }, [subAdminListExceptLoggedInAdminReg]);

  // Searching (Parth Roka)
  function MainSearch(key) {
    let token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("token", token);
    formData.append("username", frname ? frname : "");
    formData.append(
      "start_date",
      startDate ? moment(startDate).format("MM/DD/YYYY") : ""
    );
    formData.append(
      "end_date",
      endDate ? moment(endDate).format("MM/DD/YYYY") : ""
    );
    formData.append("start", key);
    formData.append("limit", 10);

    if (frname) {
      if (
        localStorage.getItem("subAdminListExceptLoggedInAdminAPI") != "true"
      ) {
        localStorage.setItem("subAdminListExceptLoggedInAdminAPI", true);
        dispatch(subAdminListExceptLoggedInAdminAPI(formData));
      }
    } else {
      if (
        localStorage.getItem("subAdminListExceptLoggedInAdminAPI") != "true"
      ) {
        localStorage.setItem("subAdminListExceptLoggedInAdminAPI", true);
        // dispatch(dispatchLoadingStatus(true));
        dispatch(subAdminListExceptLoggedInAdminAPI(formData));
      }
    }
  }

  const [startDate, setStartDate] = useState();
  const [frname, setSetFrname] = useState("");

  const openDatePicker = (e) => {
    if (e == "startDate") {
      if (startdatePickerRef && startdatePickerRef.current) {
        startdatePickerRef.current.setOpen(true);
      }
    } else {
      if (enddatePickerRef && enddatePickerRef.current) {
        enddatePickerRef.current.setOpen(true);
      }
    }
  };

  let deleteSubAdminReg = useSelector(
    (state) => state.globalReducer.deleteSubAdminReg
  );

  let AdminStatusReg = useSelector(
    (state) => state.globalReducer.AdminStatusReg
  );

  useEffect(() => {
    if (deleteSubAdminReg) {
      MainSearch((page - 1) * 10, page);
    }
  }, [deleteSubAdminReg]);

  useEffect(() => {
    if (AdminStatusReg) {
      MainSearch((page - 1) * 10, page);
    }
  }, [AdminStatusReg]);

  useEffect(() => {
    if (frname) {
      MainSearch(0);
      return;
    }

    if (frname <= 0) {
      MainSearch(0);
      return;
    }
  }, [frname]);

  useEffect(() => {
    if (startDate) {
      MainSearch(0);
      return;
    }

    if (startDate <= 0) {
      MainSearch(0);
      return;
    }
  }, [startDate]);

  const [endDate, setEndDate] = useState();

  useEffect(() => {
    if (endDate) {
      MainSearch(0);
      return;
    }

    if (endDate <= 0) {
      MainSearch(0);
      return;
    }
  }, [endDate]);

  // for Delete Admin (Parth Roka)
  function deleteRole(i) {
    swal({
      text: "Are you sure you want to delete sub admin?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let formData = new FormData();
        formData.append("token", localStorage.getItem("token"));
        formData.append("subAdminId", i._id);

        dispatch(dispatchLoadingStatus(true));
        dispatch(deleteSubAdminAPI(formData));
      } else {
        // window.location.href = window.location.origin + '/FreelancerRoleManagement';
      }
    });
  }

  // for active and inactive Switch (Parth Roka)
  function handleCheck(e, i) {
    if (e.target.checked == true) {
      swal({
        text: "Are you sure you want to active admin?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((Responce) => {
        if (Responce) {
          let formData = new FormData();
          formData.append("token", localStorage.getItem("token"));
          formData.append("subAdminId", i._id);
          dispatch(dispatchLoadingStatus(true));
          dispatch(AdminStatusAPI(formData));

          swal({
            text: "Your admin is active!",
            icon: "success",
          });
        } else {
        }
      });
    } else {
      swal({
        text: "Are you sure you want to inactive admin?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((Responce) => {
        if (Responce) {
          let formData = new FormData();
          formData.append("token", localStorage.getItem("token"));
          formData.append("subAdminId", i._id);
          dispatch(dispatchLoadingStatus(true));
          dispatch(AdminStatusAPI(formData));

          swal({
            text: "Your admin is inactive!",
            icon: "success",
          });
        } else {
        }
      });
    }
  }

  return (
    <>
      <Loader />
      <div
        className={toggleReducer == false ? "contents expanded" : "contents"}
      >
        <div className="crm admin-user-managment-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-main mt-5">
                  <h4 className="text-capitalize breadcrumb-title">
                    Admin User Management
                  </h4>
                  <div class="breadcrumb-action justify-content-center flex-wrap">
                    <div className="">
                      {" "}
                      <ul class="atbd-breadcrumb nav">
                        <li justify-content-center flex-wrapi class="atbd-breadcrumb__item"></li>
                        <li class="atbd-breadcrumb__item">
                          <a href="/Alltrip">
                            Trips
                          </a>
                          <span class="breadcrumb__seperator">
                            <span class="la la-slash"></span>
                          </span>
                        </li>
                        <li justify-content-center flex-wrapi class="atbd-breadcrumb__item"></li>
                        <li class="atbd-breadcrumb__item">
                          <a href="/Adminusermanagment">
                            Admin User Management
                          </a>
                          <span class="breadcrumb__seperator"></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="action-btn">
                  <div className="with-icon">
                    <span className="fa fa-search"></span>
                    <input
                      type="search"
                      maxLength={22}
                      className="form-control mr-sm-2 box-shadow-none"
                      placeholder="Search..."
                      onChange={(e) =>
                        setSetFrname(
                          e.target.value.trim() == ""
                            ? ""
                            : e.target.value
                        )
                      }
                      value={frname}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="action-btn">
                  <div className="atbd-date-picker">
                    <div className="form-group mb-0 form-group-calender">
                      <div className="position-relative curr-trip-date-new">
                        <DatePicker
                          name="Startdate"
                          className="form-control form-control-default"
                          placeholderText={"mm/dd/yyyy"}
                          dateFormat="MM/dd/yyyy"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          minDate={moment("01/01/1947").toDate()}
                          onChange={(e) => setStartDate(e)}
                          selected={startDate}
                          maxDate={endDate ? new Date(endDate) : new Date()}
                          ref={startdatePickerRef}
                          autoComplete="off"
                          isClearable={true}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />

                        <a onClick={() => openDatePicker("startDate")}>
                          <span data-feather="calendar"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6 mb-3">
                <div className="action-btn">
                  <div className="atbd-date-picker curr-trip-date-new">
                    <div className="form-group mb-0 form-group-calender">
                      <div className="position-relative">
                        <DatePicker
                          name="Enddate"
                          className="form-control form-control-default"
                          placeholderText={"mm/dd/yyyy"}
                          dateFormat="MM/dd/yyyy"
                          onChange={(e) => setEndDate(e)}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          minDate={startDate ? new Date(startDate) : null}
                          selected={endDate}
                          maxDate={new Date()}
                          ref={enddatePickerRef}
                          autoComplete="off"
                          isClearable={true}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />

                        <a onClick={() => openDatePicker("EndDate")}>
                          <span data-feather="calendar"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="action-btn">
                  <button
                    className="btn btn-sm btn-primary btn-add"
                    onClick={() => {
                      if (isAdminUserCreateTrue == true) {
                        history("/AddAdmin");
                      } else {
                        swal({
                          text: "you don't have permission",
                          icon: "warning",
                          dangerMode: true,
                        });
                      }
                    }}
                  >
                    <i className="la la-plus"></i> Add Admin
                  </button>
                </div>
              </div>

            </div>

            <div className="row ">
              <div className="col-lg-12">
                <div className="card card-default card-md mb-4">
                  <div className="card-body">
                    <div className="table4 bg-white mb-30 ">
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <thead>
                            <tr className="userDatatable-header">
                              <th>
                                <span className="userDatatable-title">
                                  Sr No.
                                </span>
                              </th>

                              <th>
                                <span className="userDatatable-title">
                                  User
                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Name
                                  <i onClick={() => Sortt("username")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>

                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Email
                                  <i onClick={() => Sortt("email")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>

                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Phone
                                  <i onClick={() => Sortt("phone")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>

                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Active/Inactive
                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Action
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {allData22
                              ? allData22.length > 0
                                ? allData22.map((i, index) => (
                                  <tr>
                                    <td> {page * 10 + index + 1 - 10}.</td>
                                    <td>
                                      <div className="userDatatable-content user-list">
                                        <div className="avatar avatar-circle ml-0">
                                          <img
                                            src={i.profilePicUrl ? i.profilePicUrl : ProfileImg}
                                            className="rounded-circle wh-46 d-flex bg-opacity-primary"
                                            alt="image"
                                          />
                                          <div className="badge-direction-bottom">
                                            <span className=" text-warning">
                                              {/* <i className="fa fa-crown"></i> */}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="userDatatable-content">
                                        {i.username}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="userDatatable-content">
                                        {i.email}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="userDatatable-content">
                                        {i.phone}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="atbd-switch-wrap">
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            id="toggleSwitch"
                                            checked={i.isActive}
                                            onChange={(e) => {
                                              if (
                                                isAdminUserEditTrue == true
                                              ) {
                                                handleCheck(e, i);
                                              } else {
                                                swal({
                                                  text: "you don't have permission",
                                                  icon: "warning",
                                                  dangerMode: true,
                                                });
                                              }
                                            }}
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      </div>
                                    </td>

                                    <td>
                                      <i
                                        className="cursor-pointer far fa-edit mr-3 font-size-18"
                                        onClick={() => {
                                          if (isAdminUserEditTrue == true) {
                                            history("/EditAdmin", {
                                              state: i,
                                            });
                                          } else {
                                            swal({
                                              text: "you don't have permission",
                                              icon: "warning",
                                              dangerMode: true,
                                            });
                                          }
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                      </i>

                                      <i
                                        className="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          if (isAdminUserDeleteTrue == true) {
                                            deleteRole(i);
                                          } else {
                                            swal({
                                              text: "you don't have permission",
                                              icon: "warning",
                                              dangerMode: true,
                                            });
                                          }
                                        }}
                                      ></i>
                                    </td>
                                  </tr>
                                ))
                                : ""
                              : ""}
                          </tbody>
                        </table>
                        {allData22 ? (
                          allData22.length > 0 ? null : (
                            <div style={{ textAlign: "center" }}>
                              <h6 className="pt-5">No data found</h6>{" "}
                            </div>
                          )
                        ) : null}

                      </div>
                      <div className=' btns-table align-items-center justify-content-end'>
                        {subAdminListExceptLoggedInAdminReg ? subAdminListExceptLoggedInAdminReg.count > 0 ? <div className="row align-items-center wrapper-pg-main pagination">
                          <div className='col-6'>
                          </div>
                          <div className='col-6'>
                            <div className="wrapper-pgn">
                              <div className="pgn pgn-default">
                                <div className="prev " onClick={() => document.getElementById('content').scrollBy(-50, 0)}><i className="fa fa-chevron-left" style={{ cursor: "pointer" }}></i></div>
                                <div className="pages" id="content">
                                  {(() => {
                                    const pages = [];
                                    for (let i = 1; i < (subAdminListExceptLoggedInAdminReg.count / 10) + 1; i++) {
                                      pages.push(<a className={page == i ? "active" : ""}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          MainSearch((i - 1) * 10);
                                          setpage(i);
                                          setIndexCh(indexCh + 1);
                                        }}>{i}</a>);
                                    }
                                    return pages;
                                  })()}
                                </div>
                                <div onClick={() => { document.getElementById('content').scrollBy(50, 0) }} className=" next "><i className="fa fa-chevron-right" style={{ cursor: "pointer" }}></i></div>
                              </div> </div>
                          </div>
                        </div> : null : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <MainDashboard />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AdminUserManagment;