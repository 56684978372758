import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  usersListAPI,
  dispatchLoadingStatus,
  UserStatusAPI,
  DownloadUsersListAPI,
} from '../../API/Action/globalAction';
import Loader from '../Loader/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import Footer from "../Footer/Footer";
import MainDashboard from "../Main Dashboard/MainDashboard";
import ProfileImg from "../../assets/Images/black22.jpg"

function UserList() {
  const usersListReg = useSelector((state) => state.globalReducer.usersListReg);

  let accessDataByAdminIdReg = useSelector(
    (state) => state.globalReducer.accessDataByAdminIdReg
  );

  const [access, setAccess] = useState();
  const [order, setOrder] = useState("ASC");

  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...allData22].sort((a, b) =>
        (a[col]?.toLowerCase() || "") > (b[col]?.toLowerCase() || "") ? 1 : -1
      );
      setAllData22(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...allData22].sort((a, b) =>
        (a[col]?.toLowerCase() || "") < (b[col]?.toLowerCase() || "") ? 1 : -1
      );
      setAllData22(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    if (accessDataByAdminIdReg) {
      if (accessDataByAdminIdReg.errorcode == 0) {
        setAccess(
          accessDataByAdminIdReg
            ? accessDataByAdminIdReg.data
            : accessDataByAdminIdReg.data
        );
        let alldata = accessDataByAdminIdReg.data;
        if (alldata.userManage == true) {
        } else {
          history("/Alltrip");
        }
      }
    }
  }, [accessDataByAdminIdReg]);

  let isUserlistReadTrue = access ? access.userRead : "";
  let isUserlistManageTrue = access ? access.userManage : "";
  let isUserlistEditTrue = access ? access.userEdit : "";

  const [page, setpage] = useState(1);
  const [indexCh, setIndexCh] = useState(0);
  const [allData22, setAllData22] = useState();

  const dispatch = useDispatch({});
  let history = useNavigate();
  const startdatePickerRef = useRef(null);
  const enddatePickerRef = useRef(null);

  let toggleReducer = useSelector((state) => state.globalReducer.toggle);

  useEffect(() => {
    if (usersListReg) {
      if (usersListReg.errorcode == 0) {
        // window.scrollTo(0, 0);
        setAllData22(usersListReg ? usersListReg.data : usersListReg.data);
      } else {
        setAllData22([]);
      }
    }
  }, [usersListReg]);

  function AllFlage(e, i) {
    history("/" + e, { state: i });
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    let token = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("token", token);
    formData.append("start", 0);
    formData.append("limit", 10);
    dispatch(dispatchLoadingStatus(true));
    dispatch(usersListAPI(formData));
    DownloadUsersListReg = "";
  }, []);

  function MainSearch(key) {
    let token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("token", token);
    formData.append("username", frname ? frname : "");
    formData.append(
      "start_date",
      startDate ? moment(startDate).format("MM/DD/YYYY") : ""
    );
    formData.append(
      "end_date",
      endDate ? moment(endDate).format("MM/DD/YYYY") : ""
    );
    formData.append("isActive", active ? active : "");
    formData.append("start", key);
    formData.append("limit", 10);

    dispatch(usersListAPI(formData));
  }

  const [frname, setSetFrname] = useState("");
  let UserStatusReg = useSelector((state) => state.globalReducer.UserStatusReg);
  let DownloadUsersListReg = useSelector((state) => state.globalReducer.DownloadUsersListReg);

  useEffect(() => {
    if (UserStatusReg) {
      MainSearch((page - 1) * 10, page);
    }
  }, [UserStatusReg]);

  useEffect(() => {
    if (frname) {
      MainSearch(0);
      return;
    }

    if (frname <= 0 || frname == "") {
      MainSearch(0);
      return;
    }
  }, [frname]);

  const [startDate, setStartDate] = useState(null);

  const openDatePicker = (e) => {
    if (e == "startDate") {
      if (startdatePickerRef && startdatePickerRef.current) {
        startdatePickerRef.current.setOpen(true);
      }
    } else {
      if (enddatePickerRef && enddatePickerRef.current) {
        enddatePickerRef.current.setOpen(true);
      }
    }
  };

  useEffect(() => {
    if (startDate) {
      MainSearch(0);
      return;
    }

    if (startDate <= 0) {
      MainSearch(0);
      return;
    }
  }, [startDate]);

  const [endDate, setEndDate] = useState();

  useEffect(() => {
    if (endDate) {
      MainSearch(0);
      return;
    }

    if (endDate <= 0) {
      MainSearch(0);
      return;
    }
  }, [endDate]);

  const options = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "InActive" },
  ];

  const [active, setActive] = useState("");

  const handleclearSelection = () => {
    var select = document.getElementById("mySelect");
    select.selectedIndex = 0;
    setActive("");
  };

  useEffect(() => {
    if (active) {
      MainSearch(0);
      return;
    }
    if (!active) {
      MainSearch(0);
      return;
    }
  }, [active]);

  const handleDownload = () => {
    let token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("token", token);
    formData.append("username", frname ? frname : "");
    formData.append("start_date", startDate ? moment(startDate).format("MM/DD/YYYY") : "");
    formData.append("end_date", endDate ? moment(endDate).format("MM/DD/YYYY") : "");
    formData.append("isActive", active ? active : "");
    dispatch(DownloadUsersListAPI(formData));

  }

  useEffect(() => {
    if (DownloadUsersListReg) {
      let data = DownloadUsersListReg ? DownloadUsersListReg.path : ""
      window.open(data, '_blank');
    }
  }, [DownloadUsersListReg]);

  function handleCheck(e, i) {
    if (e.target.checked == true) {
      swal({
        text: "Are you sure you want to active user?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((Responce) => {
        if (Responce) {
          let formData = new FormData();
          formData.append("token", localStorage.getItem("token"));
          formData.append("userId", i._id);
          dispatch(dispatchLoadingStatus(true));
          dispatch(UserStatusAPI(formData));

          swal({
            text: "Your user is active!",
            icon: "success",
          });
        }
      });
    } else {
      swal({
        text: "Are you sure you want to inactive user?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((Responce) => {
        if (Responce) {
          let formData = new FormData();
          formData.append("token", localStorage.getItem("token"));
          formData.append("userId", i._id);
          dispatch(dispatchLoadingStatus(true));
          dispatch(UserStatusAPI(formData));

          swal({
            text: "Your user is inactive!",
            icon: "success",
          });
        }
      });
    }
  }

  return (
    <>
      <Loader />
      <div
        className={toggleReducer == false ? "contents expanded" : "contents"}
      >
        <div className="crm">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-main mt-5">
                  <h4 className="text-capitalize breadcrumb-title">
                    User List
                  </h4>
                  <div className="breadcrumb-action justify-content-center flex-wrap">
                    <div className="">
                      <ul className="atbd-breadcrumb nav">
                        <li class="atbd-breadcrumb__item">

                        </li>
                        <li className="atbd-breadcrumb__item">
                          <a href="/Alltrip">Trips</a>
                          <span className="breadcrumb__seperator">
                            <span className="la la-slash"></span>
                          </span>
                        </li>
                        <li className="atbd-breadcrumb__item">
                          <a href="/Userlist">User List</a>
                          <span className="breadcrumb__seperator">
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
                <div className="breadcrumb-main ">
                  <div className="breadcrumb-action justify-content-center flex-wrap">
                    <div className="action-btn custom-select-1  ">
                      <select
                        className="select-travel select-trans  mr-2"
                        name="qualification"
                        id="mySelect"
                        onChange={(e) => {
                          !e ? setActive("") : setActive(e.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {options.map((i) => (
                          <option value={i.value} key={i.value} >
                            {i.label}
                          </option>
                        ))}
                      </select>
                      {active ? (
                        <button
                          onClick={() => handleclearSelection()}
                          className=" border-0 bg-none select-close-btn"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            width={10}
                          >
                            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                          </svg>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="action-btn">
                      <div className="with-icon">
                        <span className="fa fa-search"></span>
                        <input
                          className="form-control mr-sm-2 box-shadow-none"
                          type="search"
                          placeholder="Search..."
                          maxLength={20}
                          aria-label="Search"
                          onChange={(e) =>
                            setSetFrname(
                              e.target.value.trim() == "" ? "" : e.target.value
                            )
                          }
                          value={frname}
                        />
                      </div>
                    </div>

                    <div className="action-btn ">
                      <div className="atbd-date-picker">
                        <div className="form-group mb-0 form-group-calender">
                          <div className="position-relative curr-trip-date-new">
                            <DatePicker
                              name="Startdate"
                              className="form-control form-control-default"
                              placeholderText={"mm/dd/yyyy"}
                              dateFormat="MM/dd/yyyy"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              minDate={moment("01/01/1947").toDate()}
                              onChange={(e) => setStartDate(e)}
                              selected={startDate}
                              maxDate={endDate ? new Date(endDate) : new Date()}
                              ref={startdatePickerRef}
                              autoComplete="off"
                              isClearable={true}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                            <a onClick={() => openDatePicker("startDate")}>
                              <i className="far fa-calendar text-light"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="action-btn ">
                      <div className="atbd-date-picker">
                        <div className="form-group mb-0 form-group-calender">
                          <div className="position-relative curr-trip-date-new">
                            <DatePicker
                              name="Enddate"
                              className="form-control form-control-default"
                              placeholderText={"mm/dd/yyyy"}
                              dateFormat="MM/dd/yyyy"
                              onChange={(e) => setEndDate(e)}
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              minDate={startDate ? new Date(startDate) : null}
                              selected={endDate}
                              maxDate={new Date()}
                              ref={enddatePickerRef}
                              autoComplete="off"
                              isClearable={true}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />

                            <a onClick={() => openDatePicker("EndDate")}>
                              <i className="far fa-calendar text-light"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card card-default card-md mb-4">
                  <div class="col-lg-12 mt-4 ">
                    <button class="view-all btn btn-primary text-right ml-auto"
                      onClick={() => {
                        handleDownload()
                      }}
                    >
                      Download
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="table4 bg-white mb-30">
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <thead>
                            <tr className="userDatatable-header">
                              <th>
                                <span className="userDatatable-title">
                                  Sr No.
                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  User
                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Name
                                  <i onClick={() => Sortt("firstName")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>

                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Email
                                  <i onClick={() => Sortt("email")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>

                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Date of Joining
                                  <i onClick={() => Sortt("createdDate")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>

                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Phone
                                  <i onClick={() => Sortt("phone")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>

                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Active/Inactive
                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Action
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {allData22
                              ? allData22.length > 0
                                ? allData22.map((i, index) => (
                                  <tr>
                                    <td> {page * 10 + index + 1 - 10}.</td>
                                    <td>
                                      <div className="userDatatable-content user-list">
                                        <div className="avatar avatar-circle ml-0">
                                          <img
                                            src={i.profilePicUrl ? i.profilePicUrl : ProfileImg}
                                            className="rounded-circle wh-46 d-flex bg-opacity-primary"
                                            alt="image"
                                          />
                                          <div className="badge-direction-bottom">
                                            <span className=" text-warning">
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="userDatatable-content">
                                        {i.firstName} {i.lastName}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="userDatatable-content">
                                        {i.email}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="userDatatable-content">
                                        {i.createdDate}
                                      </div>
                                    </td>

                                    <td>
                                      <div className="userDatatable-content">
                                        {i.phone ? i.phone : <p className="text-center">-</p>}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="atbd-switch-wrap">
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            id="toggleSwitch"
                                            checked={i.isActive}
                                            onChange={(e) => {
                                              if (
                                                isUserlistEditTrue == true
                                              ) {
                                                handleCheck(e, i);
                                              } else {
                                                swal({
                                                  text: "you don't have permission",
                                                  icon: "warning",
                                                  dangerMode: true,
                                                });
                                              }
                                            }}
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      <i
                                        className="cursor-pointer far fa-eye ms-3 font-size-18"
                                        onClick={() => {
                                          if (
                                            isUserlistReadTrue == true &&
                                            isUserlistManageTrue == true
                                          ) {
                                            AllFlage("Userdetails", i);
                                          } else {
                                            swal({
                                              text: "you don't have permission",
                                              icon: "warning",
                                              // buttons: true,
                                              dangerMode: true,
                                            });
                                          }
                                        }}
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </td>
                                  </tr>
                                ))
                                : ""
                              : ""}
                          </tbody>
                        </table>
                      </div>
                      {allData22 ? (
                        allData22.length > 0 ? null : (
                          <div style={{ textAlign: "center", fontWeight: "500", color: "#000", marginTop: "25px", }}>
                            <p>No data found</p>{" "}
                          </div>
                        )
                      ) : null}

                      <div className=' btns-table align-items-center justify-content-end'>
                        {usersListReg ? usersListReg.count > 0 ? <div className="row align-items-center wrapper-pg-main pagination">
                          <div className='col-6'>
                          </div>
                          <div className='col-6'>
                            <div className="wrapper-pgn">
                              <div className="pgn pgn-default">
                                <div className="prev " onClick={() => document.getElementById('content').scrollBy(-50, 0)}><i className="fa fa-chevron-left" style={{ cursor: "pointer" }}></i></div>
                                <div className="pages" id="content">
                                  {(() => {
                                    const pages = [];

                                    for (let i = 1; i < (usersListReg.count / 10) + 1; i++) {
                                      pages.push(<a className={page == i ? "active" : ""}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          MainSearch((i - 1) * 10);
                                          setpage(i);
                                          setIndexCh(indexCh + 1);
                                        }}>{i}</a>);
                                    }
                                    return pages;
                                  })()}
                                </div>
                                <div onClick={() => { document.getElementById('content').scrollBy(50, 0) }} className=" next "><i className="fa fa-chevron-right" style={{ cursor: "pointer" }}></i></div>
                              </div> </div>
                          </div>
                        </div> : null : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <MainDashboard />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default UserList;
