import {
  AdminLoginReg,
  OtpReg,
  toggle,
  usersListReg,
  UpcomingTripReg,
  CurrentTripsReg,
  CompletedTripsReg,
  UndefinedTripsReg,
  TripListVariousCountDataReg,
  subAdminListExceptLoggedInAdminReg,
  addSubAdminReg,
  editSubAdminReg,
  deleteSubAdminReg,
  accessDataByAdminIdReg,
  AdminStatusReg,
  UserStatusReg,
  TripDataByUserIdReg,
  addCategoryReg,
  editCategoryReg,
  listAllCategoryReg,
  changeCategoryStatusReg,
  saveInquiryReg,
  AllInquiryReg,
  InquiryExcelReg,
  deleteCategoryReg,
  DownloadUsersListReg,
  deleteUserReg,
  accommodationListReg,
  accommodationHotelListReg,
  tripDetailsReg,
} from "../../Constant";

let defaultState = {
  AdminLoginReg: null,
  OtpReg: null,
  toggle: true,
  usersListReg: null,
  UpcomingTripReg: null,
  CurrentTripsReg: null,
  CompletedTripsReg: null,
  UndefinedTripsReg: null,
  TripListVariousCountDataReg: null,
  subAdminListExceptLoggedInAdminReg: null,
  addSubAdminReg: null,
  editSubAdminReg: null,
  deleteSubAdminReg: null,
  accessDataByAdminIdReg: null,
  AdminStatusReg: null,
  UserStatusReg: null,
  TripDataByUserIdReg: null,
  addCategoryReg: null,
  editCategoryReg: null,
  listAllCategoryReg: null,
  changeCategoryStatusReg: null,
  saveInquiryReg: null,
  AllInquiryReg: null,
  InquiryExcelReg: null,
  deleteCategoryReg: null,
  DownloadUsersListReg: null,
  deleteUserReg: null,
  accommodationListReg: null,
  accommodationHotelListReg : null,
  tripDetailsReg: null,
};

const globalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AdminLoginReg:
      return Object.assign({}, state, {
        AdminLoginReg: action.payload,
      });
    case OtpReg:
      return Object.assign({}, state, {
        OtpReg: action.payload,
      });
    case toggle:
      return Object.assign({}, state, {
        toggle: action.payload,
      });
    case usersListReg:
      return Object.assign({}, state, {
        usersListReg: action.payload,
      });
    case UpcomingTripReg:
      return Object.assign({}, state, {
        UpcomingTripReg: action.payload,
      });
    case CurrentTripsReg:
      return Object.assign({}, state, {
        CurrentTripsReg: action.payload,
      });
    case CompletedTripsReg:
      return Object.assign({}, state, {
        CompletedTripsReg: action.payload,
      });
    case UndefinedTripsReg:
      return Object.assign({}, state, {
        UndefinedTripsReg: action.payload,
      });
    case TripListVariousCountDataReg:
      return Object.assign({}, state, {
        TripListVariousCountDataReg: action.payload,
      });
    case subAdminListExceptLoggedInAdminReg:
      return Object.assign({}, state, {
        subAdminListExceptLoggedInAdminReg: action.payload,
      });
    case addSubAdminReg:
      return Object.assign({}, state, {
        addSubAdminReg: action.payload,
      });
    case editSubAdminReg:
      return Object.assign({}, state, {
        editSubAdminReg: action.payload,
      });
    case deleteSubAdminReg:
      return Object.assign({}, state, {
        deleteSubAdminReg: action.payload,
      });
    case accessDataByAdminIdReg:
      return Object.assign({}, state, {
        accessDataByAdminIdReg: action.payload,
      });
    case AdminStatusReg:
      return Object.assign({}, state, {
        AdminStatusReg: action.payload,
      });
    case UserStatusReg:
      return Object.assign({}, state, {
        UserStatusReg: action.payload,
      });
    case TripDataByUserIdReg:
      return Object.assign({}, state, {
        TripDataByUserIdReg: action.payload,
      });
    case addCategoryReg:
      return Object.assign({}, state, {
        addCategoryReg: action.payload,
      });
    case editCategoryReg:
      return Object.assign({}, state, {
        editCategoryReg: action.payload,
      });
    case listAllCategoryReg:
      return Object.assign({}, state, {
        listAllCategoryReg: action.payload,
      });
    case changeCategoryStatusReg:
      return Object.assign({}, state, {
        changeCategoryStatusReg: action.payload,
      });
    case saveInquiryReg:
      return Object.assign({}, state, {
        saveInquiryReg: action.payload,
      });
    case AllInquiryReg:
      return Object.assign({}, state, {
        AllInquiryReg: action.payload,
      });
      case InquiryExcelReg:
      return Object.assign({}, state, {
        InquiryExcelReg: action.payload,
      });
     case deleteCategoryReg:
      return Object.assign({}, state, {
        deleteCategoryReg: action.payload,
      });
      case DownloadUsersListReg:
        return Object.assign({}, state, {
          DownloadUsersListReg: action.payload,
        });
        case deleteUserReg:
        return Object.assign({}, state, {
          deleteUserReg: action.payload,
        });
        case accommodationListReg:
          return Object.assign({}, state, {
            accommodationListReg: action.payload,
          });
          case accommodationHotelListReg:
            return Object.assign({}, state, {
              accommodationHotelListReg: action.payload,
            });
            case tripDetailsReg:
              return Object.assign({}, state, {
                tripDetailsReg: action.payload,
              });
        

    default:
      return state;
  }
};
export default globalReducer;
