import React, { useEffect, useState, useRef } from "react";
import MainDashboard from "../Main Dashboard/MainDashboard";
import { useDispatch, useSelector } from "react-redux";
import Loader from '../Loader/Loader';
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  AllInquiryAPI,
  dispatchLoadingStatus,
  InquiryExcelAPI,
} from "../../API/Action/globalAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";

// Added by Parth Roka
function InquiryDetails() {

  const [order, setOrder] = useState("ASC");

  // for sorting (Parth Roka)
  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...AllTrips].sort((a, b) =>
        (a[col]?.toLowerCase() || "") > (b[col]?.toLowerCase() || "") ? 1 : -1
      );
      setAllTrips(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...AllTrips].sort((a, b) =>
        (a[col]?.toLowerCase() || "") < (b[col]?.toLowerCase() || "") ? 1 : -1
      );
      setAllTrips(sorted);
      setOrder("ASC");
    }
  };
  const dispatch = useDispatch({});
  let history = useNavigate();

  const startdatePickerRef = useRef(null);
  const enddatePickerRef = useRef(null);

  let toggleReducer = useSelector((state) => state.globalReducer.toggle);

  let AllInquiryReg = useSelector((state) => state.globalReducer.AllInquiryReg);

  let accessDataByAdminIdReg = useSelector(
    (state) => state.globalReducer.accessDataByAdminIdReg
  );

  let InquiryExcelReg = useSelector((state) => state.globalReducer.InquiryExcelReg);
  const [access, setAccess] = useState();

  useEffect(() => {
    if (accessDataByAdminIdReg) {
      if (accessDataByAdminIdReg.errorcode == 0) {
        setAccess(
          accessDataByAdminIdReg
            ? accessDataByAdminIdReg.data
            : accessDataByAdminIdReg.data
        );
        let alldata = accessDataByAdminIdReg.data;
        if (alldata.inquiryManage == true) {
        } else {
          history("/Alltrip");
        }
      }
    }
  }, [accessDataByAdminIdReg]);

  let isInquiryReadTrue = access ? access.inquiryRead : "";
  let isInquiryManageTrue = access ? access.inquiryManage : "";

  useEffect(() => {
    InquiryExcelReg = ""
  }, []);

  const [page, setpage] = useState(1);
  const [indexCh, setIndexCh] = useState(0);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    let token = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("token", token);
    formData.append("start", 0);
    formData.append("limit", 10);
    dispatch(dispatchLoadingStatus(true));
    dispatch(AllInquiryAPI(formData));
  }, []);

  const [AllTrips, setAllTrips] = useState("");

  const [EmailName, setEmailName] = useState();

  useEffect(() => {
    if (AllInquiryReg) {
      if (AllInquiryReg.errorcode == 0) {
        setAllTrips(AllInquiryReg ? AllInquiryReg.data : AllInquiryReg.data);
      } else {
        setAllTrips([]);
      }
    }
  }, [AllInquiryReg]);

  useEffect(() => {
    if (EmailName) {
      MainSearch(0);
      return;
    }

    if (EmailName <= 0) {
      MainSearch(0);
      return;
    }
  }, [EmailName]);

  function MainSearch(key) {
    let token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("token", token);
    formData.append("email", EmailName ? EmailName : "");
    formData.append("Fromdate", startDate ? moment(startDate).format("YYYY-MM-DD") : "");
    formData.append("Todate", endDate ? moment(endDate).format("YYYY-MM-DD") : "");
    formData.append("start", key);
    formData.append("limit", 10);
    dispatch(AllInquiryAPI(formData));
  }

  const [startDate, setStartDate] = useState(null);

  const openDatePicker = (e) => {
    if (e == "startDate") {
      if (startdatePickerRef && startdatePickerRef.current) {
        startdatePickerRef.current.setOpen(true);
      }
    } else {
      if (enddatePickerRef && enddatePickerRef.current) {
        enddatePickerRef.current.setOpen(true);
      }
    }
  };

  const [endDate, setEndDate] = useState(null);

  // for download inquiry list (Parth Roka)
  const handleDownload = () => {
    let token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("token", token);
    formData.append("email", EmailName ? EmailName : "");
    formData.append("Fromdate", startDate ? moment(startDate).format("YYYY-MM-DD") : "");
    formData.append("Todate", endDate ? moment(endDate).format("YYYY-MM-DD") : "");
    dispatch(InquiryExcelAPI(formData));

  }

  useEffect(() => {
    if (InquiryExcelReg) {
      let data = InquiryExcelReg ? InquiryExcelReg.path : ""

      window.open(data, '_blank');
    }
  }, [InquiryExcelReg]);

  useEffect(() => {
    if (startDate) {
      MainSearch(0);
      return;
    }

    if (startDate <= 0) {
      MainSearch(0);
      return;
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      MainSearch(0);
      return;
    }

    if (endDate <= 0) {
      MainSearch(0);
      return;
    }
  }, [endDate]);

  return (
    <>
      <Loader />
      <div
        className={toggleReducer == false ? "contents expanded" : "contents"}
      >
        <div className="crm admin-user-managment-sec">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-lg-12">
                <div className="breadcrumb-main mt-5">
                  <h4 className="text-capitalize breadcrumb-title">
                    Inquiry Details
                  </h4>

                  <div class="breadcrumb-action justify-content-center flex-wrap">
                    <div className="">
                      <ul class="atbd-breadcrumb nav">
                        <li class="atbd-breadcrumb__item"></li>
                        <li class="atbd-breadcrumb__item">
                          <a href="/Alltrip" className="mr-2">Trips</a>
                          <span class="breadcrumb__seperator">/</span>
                          <a href="/Inquirydetails" className="ml-2">Inquiry Details</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="action-btn">
                  <div className="with-icon">
                    <span className="fa fa-search"></span>
                    <input
                      type="search"
                      className="form-control mr-sm-2 box-shadow-none"
                      placeholder="Search..."
                      maxLength={25}
                      onChange={(e) =>
                        setEmailName(
                          e.target.value.trim() == ""
                            ? ""
                            : e.target.value
                        )
                      }
                      value={EmailName}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="action-btn">
                  <div className="atbd-date-picker">
                    <div className="form-group mb-0 form-group-calender">
                      <div className="position-relative curr-trip-date-new">
                        <DatePicker
                          name="Startdate"
                          className="form-control form-control-default"
                          placeholderText={"mm/dd/yyyy"}
                          dateFormat="MM/dd/yyyy"
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          minDate={moment("01/01/1947").toDate()}
                          onChange={(e) => setStartDate(e)}
                          selected={startDate}
                          maxDate={
                            endDate ? new Date(endDate) : new Date()
                          }
                          ref={startdatePickerRef}
                          autoComplete="off"
                          isClearable={true}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />

                        <a onClick={() => openDatePicker("startDate")}>
                          <i className="far fa-calendar text-light"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6">
                <div className="action-btn">
                  <div className="atbd-date-picker">
                    <div className="form-group mb-0 form-group-calender">
                      <div className="position-relative curr-trip-date-new">
                        <DatePicker
                          name="Enddate"
                          className="form-control form-control-default"
                          placeholderText={"mm/dd/yyyy"}
                          dateFormat="MM/dd/yyyy"
                          onChange={(e) => setEndDate(e)}
                          showMonthDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          minDate={startDate ? new Date(startDate) : null}
                          selected={endDate}
                          maxDate={new Date()}
                          ref={enddatePickerRef}
                          autoComplete="off"
                          isClearable={true}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                        <a onClick={() => openDatePicker("EndDate")}>
                          <i className="far fa-calendar text-light"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-12">
                <div className="card card-default card-md mb-4">
                  <div class="col-lg-12 mt-4 ">
                    <button class="view-all btn btn-primary text-right ml-auto"
                      onClick={() => {
                        if (isInquiryManageTrue == true && isInquiryReadTrue == true) {
                          handleDownload()
                        } else {
                          swal({
                            text: "you don't have permission",
                            icon: "warning",
                            dangerMode: true,
                          });
                        }
                      }}>
                      Download
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="table4 bg-white mb-30 ">
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <thead>
                            <tr className="userDatatable-header">
                              <th>
                                <span className="userDatatable-title">
                                  Sr No.
                                </span>
                              </th>

                              <th>
                                <span className="userDatatable-title">
                                  Email
                                  <i onClick={() => Sortt("email")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>

                                </span>
                              </th>

                              <th>
                                <span className="userDatatable-title">
                                  Created Date
                                  <i onClick={() => Sortt("createdDate")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {AllTrips
                              ? AllTrips.length > 0
                                ? AllTrips.map((i, index) => (
                                  <tr>
                                    <td> {page * 10 + index + 1 - 10}.</td>

                                    <td>
                                      <div className="userDatatable-content">
                                        {i.email}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="userDatatable-content">
                                        {i.createdDate}
                                      </div>
                                    </td>
                                  </tr>
                                ))
                                : ""
                              : ""}
                          </tbody>
                        </table>
                        {AllTrips ? (
                          AllTrips.length > 0 ? null : (
                            <div style={{ textAlign: "center" }}>
                              <h6 className="pt-5">No data found</h6>{" "}
                            </div>
                          )
                        ) : null}
                      </div>
                      <div className=' btns-table align-items-center justify-content-end'>

                        {AllInquiryReg ? AllInquiryReg.count > 0 ? <div className="row align-items-center wrapper-pg-main pagination">
                          <div className='col-6'>
                          </div>
                          <div className='col-6'>
                            <div className="wrapper-pgn">
                              <div className="pgn pgn-default">
                                <div className="prev " onClick={() => document.getElementById('content').scrollBy(-50, 0)}><i className="fa fa-chevron-left" style={{ cursor: "pointer" }}></i></div>
                                <div className="pages" id="content">
                                  {(() => {
                                    const pages = [];
                                    for (let i = 1; i < (AllInquiryReg.count / 10) + 1; i++) {
                                      pages.push(<a className={page == i ? "active" : ""}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          MainSearch((i - 1) * 10);
                                          setpage(i);
                                          setIndexCh(indexCh + 1);
                                        }}>{i}</a>);
                                    }
                                    return pages;
                                  })()}
                                </div>
                                <div onClick={() => { document.getElementById('content').scrollBy(50, 0) }} className=" next "><i className="fa fa-chevron-right" style={{ cursor: "pointer" }}></i></div>
                              </div> </div>
                          </div>
                        </div> : null : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <MainDashboard />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InquiryDetails;
