import React, { useEffect, useState, } from 'react';
import { useDispatch } from 'react-redux';
import { LoginAPI, dispatchLoadingStatus } from '../../API/Action/globalAction';
import Loader from '../Loader/Loader';
import logoWhite from "../../assets/Images/logo-white.svg"

// Added by Parth Roka
function Login() {

  const dispatch = useDispatch({});

  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState("")
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value) {
      if (!isValidEmail(e.target.value)) {
        setEmailError(<p style={{ color: '#f46a6a' }}>Email not valid</p>)
      }
      else {
        setEmailError("")
      }
    }
    else {
      setEmailError(<p style={{ color: '#f46a6a' }}>Email is required</p>);
    }
  }

  const Login = (e) => {
    e.preventDefault();


    // Validation Add (Parth Roka)
    if (email) {

      if (!isValidEmail(email)) {
        setEmailError(<p style={{ color: '#f46a6a' }}>Email not valid</p>)
      }
      else {
        setEmailError("")
      }
    }
    else {
      setEmailError(<p className='newwCard error-msg'>Email is required</p>);
    }

    // Call Login API (Parth Roka)
    if (email && emailError == '') {

      let formData = new FormData();

      formData.append('email', email);
      localStorage.setItem("sendEmail", email)

      dispatch(dispatchLoadingStatus(true))
      dispatch(LoginAPI(formData))

    }
  }

// Screen Redirect (Parth Roka)
  useEffect(() => {
    localStorage.clear();
    dispatch(dispatchLoadingStatus(false))
    const token = localStorage.getItem('token');

    if (token) {
      if (!window.location.href.includes('Alltrip')) {
        window.location.href = window.location.origin + '/Alltrip'
      }
    } else {
      if (!window.location.href.includes('/Login')) {
        window.location.href = window.location.origin + '/Login'
      }
    }

  }, [])

  return (
    <>
      <Loader />

      <main className="main-content">
        <div className="signUP-admin">
          <div className="container-fluid">
            <div className="row justify-content-center align-content-center">
              <div className="col-xl-4 col-lg-5 col-md-5 p-0">
              </div>
              <div className="col-xl-8 col-lg-7 col-md-7 col-sm-8">
                <div className="signUp-admin-right signIn-admin-right  p-md-40 p-10">
                  <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8 col-md-12">
                      <div className="edit-profile login-div mt-md-25 mt-0">
                        <div className=" border-0 ">
                          <div className=" border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                            <div className="edit-profile__title text-center">
                              <img src={logoWhite} className="logo" />
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="">
                              <div className="form-group row mb-25">
                                <div className="col-sm-12 d-flex aling-items-center">
                                  <label htmlFor="inputEmailIcon" className=" col-form-label color-dark fs-16 fw-500 align-center text-white">Email
                                    ID</label>
                                </div>
                                <div className="col-sm-12">
                                  <div className="with-icon">
                                    <span className="fa fa-envelope color-gray"></span>
                                    <input type="email" className="form-control fs-16  ih-medium ip-gray radius-xs b-light" id="inputEmailIcon" placeholder="Enter your Email ID" value={email} onChange={handleEmail} maxLength={320} />
                                  </div>
                                  {emailError}
                                </div>
                              </div>
                              <div className="button-group d-block pt-1 justify-content-md-start justify-content-center m-auto text-center">
                                <button className="btn btn-primary btn-default m-auto btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn " onClick={Login}>
                                  Login
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
    </>
  )
}

export default Login;
