import  Login from './Component/Authentication/Login';
import  Otp from './Component/Authentication/Otp';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import React,{useEffect } from 'react';
import UserList from './Component/User List/UserList';
import AdminUserManagment from './Component/Admin User Management/AdminUserManagment';
import TripDeatils from './Component/Trips/TripDeatils';
import AllTrip from './Component/Trips/AllTrip';
import Footer from './Component/Footer/Footer';
import EditAdmin from './Component/Admin User Management/EditAdmin';
import CurrentTrips from './Component/Trips/CurrentTrips';
import UpcomingTrips from './Component/Trips/UpcomingTrips';
import CompletedTrips from './Component/Trips/CompletedTrips';
import UndefinedTrips from './Component/Trips/UndefinedTrips';
import UserDetails from './Component/User List/UserDetails';
import PollsList from './Component/Poll List/PollsList';
import { isMainProject } from './Constant';
import InquiryDetails from './Component/Inquiry Details/InquiryDetails';
import AddAdmin from './Component/Admin User Management/AddAdmin';

function App() {

  useEffect(() => {

    if (isMainProject) {
      if (window.location.href == window.location.origin + '/') {
        if (localStorage.getItem('token')) {
          window.location.href = window.location.origin + '/Alltrip'
        } else {
          window.location.href = window.location.origin + '/Login'
        }
      }
    }
  }, [])
  return (
    <div >
      <Router>
        <Routes>

          <Route exact path="/Login" element={<Login />} />       
          <Route exact path="/Otp" element={<Otp />} />

          <Route exact path="/Userlist" element={<UserList />} />
          <Route exact path="/Userdetails" element={<UserDetails />} /> 

          <Route exact path="/Adminusermanagment" element={<AdminUserManagment />} />
          <Route exact path="/EditAdmin" element={<EditAdmin />} />
          <Route exact path="/AddAdmin" element={<AddAdmin />} />

          <Route exact path="/Alltrip" element={<AllTrip />} />
          <Route exact path="/Tripdetails" element={<TripDeatils />} /> 
          <Route exact path="/Currenttrips" element={<CurrentTrips />} />
          <Route exact path="/Upcomingtrips" element={<UpcomingTrips />} />
          <Route exact path="/Completedtrips" element={<CompletedTrips />} />
          <Route exact path="/Undefinedtrips" element={<UndefinedTrips />} />

          <Route exact path="/Pollslist" element={<PollsList />} />

          <Route exact path="/Inquirydetails" element={<InquiryDetails />} />
          
          <Route exact path="/Footer" element={<Footer />} />
            
        </Routes>
      </Router>

    </div>
    

  );
}

export default App;
