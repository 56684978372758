import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import feather from "feather-icons";
import { dispatchLoadingStatus, accessDataByAdminIdAPI } from "../../API/Action/globalAction";
import { useDispatch, useSelector } from "react-redux";
import { toggle } from "../../Constant";
import ProfileImg from "../../assets/Images/black22.jpg"
import logo from "../../assets/Images/logo.svg"
import bars from "../../assets/Images/bars.svg"

// Added by Parth Roka
function MainDashbord() {
  const dispatch = useDispatch({});

  let toggleReducer = useSelector((state) => state.globalReducer.toggle);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("token", token);
    dispatch(dispatchLoadingStatus(true));
    dispatch(accessDataByAdminIdAPI(formData));
    if (toggleReducer == undefined) {
      toggleReducer = true
      setOn(true)
      return
    }
    if (toggleReducer == true) {
      setOn(true)
    }
    else {
      setOn(false)
    }
  }, []);

  let accessDataByAdminIdReg = useSelector(
    (state) => state.globalReducer.accessDataByAdminIdReg
  );

  const [access, setAccess] = useState();

  useEffect(() => {
    if (accessDataByAdminIdReg) {
        if (accessDataByAdminIdReg.errorcode == 0) {
          setAccess(accessDataByAdminIdReg ? accessDataByAdminIdReg.data : accessDataByAdminIdReg.data)
      }
    }
  }, [accessDataByAdminIdReg]);

  let isUserlistManageTrue = access ? access.userManage  : "";
  let isTripsManageTrue = access ? access.tripManage  : "";
  let isAdminUserManageTrue = access ? access.adminUserManagementManage : "";
  let isPollsManageTrue = access ? access.pollsManage : "";
  let isInquiryTrue = access ? access.inquiryManage : "";

  useEffect(() => {
    if (toggleReducer == true) {
      setOn(true)
    }
    else {
      setOn(false)
    }

  }, [toggleReducer])

  const [dots, setDots] = useState(false);
  const [search, setSearch] = useState(false);

  const [on, setOn] = useState(toggleReducer);
  let history = useNavigate();
  function AllFlage(e) {
    history("/" + e);
  }
  function handleSlideMenu() {
    setOn(!on);

    dispatch({ type: toggle, payload: !on });
  }
  function handleDots() {
    setDots(!dots);
  }
  function handleSearch() {
    setSearch(!search);
  }

  function LogOut() {
    confirmAlert({
      message: "Are you sure you want to logout?",
      buttons: [
        {
          label: "Cancel",
          onClick: () => { },
        },
        {
          label: "Yes",
          onClick: () => {
            // history('/')
            window.location.href = window.location.origin + "/Login";
          },
        },
      ],
    });
    //
  }

  useEffect(() => {
    feather.replace();
  }, []);

  return (
    <>
      <div
        className={search == true ? "mobile-search show" : "mobile-search"}
      ></div>

      <div
        className={
          dots == true ? "mobile-author-actions show" : "mobile-author-actions"
        }
      ></div>
      <header className="header-top">
        {/* <Loader/> */}
        {/* <Dashboard/> */}
        <nav className="navbar navbar-light">
          <div className="navbar-left">
            <div className="sidebar-toggle" style={{ cursor: "pointer" }} onClick={() => handleSlideMenu()}>
              <img className="svg" src={bars} alt="img" />
            </div>

            <a className="navbar-brand nav-logo" href="/Alltrip">
              <img className="svg dark" src={logo} alt="svg" />
              <img className="light" src={logo} alt="img" />
            </a>
          </div>

          <div className="navbar-right">
            <ul className="navbar-right__menu">
              <li className="nav-search d-none">
                <a href="#" className="search-toggle">
                  <i className="la la-search"></i>
                  <i className="la la-times"></i>
                </a>
                <form
                  className="search-form-topMenu"
                >
                  <span className="search-icon" data-feather="search"></span>
                  <input
                    className="form-control mr-sm-2 box-shadow-none"
                    type="search"
                    placeholder="Search..."
                    aria-label="Search"
                  />
                </form>
              </li>

              <li className="nav-author " style={{ cursor: "pointer" }}>
                <div className="dropdown-custom">
                  <div className="d-flex align-items-center">
                    <a className="nav-item-toggle">
                      <img
                        src={ProfileImg}
                        alt=""
                        className="rounded-circle"
                      />
                    </a>
                    <div>
                      <h6 className="ml-2 mb-0 pb-0">
                        {localStorage.getItem("nameAdmin")}
                      </h6>
                    </div>
                  </div>
                  <div className="dropdown-wrapper">
                    <div className="nav-author__options ">
                      <a
                        onClick={() => LogOut()}
                        className="nav-author__signout"
                        style={{ cursor: "pointer" }}
                      >
                        <span data-feather="log-out"></span> Sign Out
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="navbar-right__mobileAction d-md-none">
              <a
                onClick={() => handleSearch()}
                className={
                  search == true ? "btn-search search-active" : "btn-search"
                }
              >
                <span data-feather="search"></span>
                <span data-feather="x"></span>
              </a>
              <a onClick={() => handleDots()} className="btn-author-action">
                <span data-feather="more-vertical"></span>
              </a>
            </div>
          </div>
        </nav>
      </header>

      <main className="main-content" >
        <aside style={{ cursor: "pointer" }}
          className={
            on
              ? "sidebar mCustomScrollbar _mCS_1 mCS-autoHide"
              : "sidebar mCustomScrollbar _mCS_1 mCS-autoHide collapsed mCS_no_scrollbar"
          }
        >
          <div className="sidebar__menu-group">
            <ul className="sidebar_nav">
              <li className="">
                <a
                  className={
                    window.location.href.includes("/Alltrip") ||
                      window.location.href.includes("/Currenttrips") ||
                      window.location.href.includes("/Upcomingtrips") ||
                      window.location.href.includes("/Completedtrips") ||
                      window.location.href.includes("/Tripdetails") ||
                      window.location.href.includes("/Undefinedtrips")
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    AllFlage("Alltrip");
                  }}
                  style={
                    isTripsManageTrue == false
                      ? { "pointer-events": "none", opacity: ".5" }
                      : {}
                  }
                >
                  <span data-feather="map" className="nav-icon"></span>

                  <span className="menu-text" style={{ cursor: "pointer" }}>
                    Trips
                  </span>
                </a>
              </li>
              <li className="">
                <a
                  className={
                    window.location.href.includes("/Userlist") |
                      window.location.href.includes("/Userdetails")
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    AllFlage("Userlist");
                  }}
                  style={
                    isUserlistManageTrue == false
                      ? { "pointer-events": "none", opacity: ".5" }
                      : {}
                  }
                >
                  {/* style={isUserlistManage == false ?  { "pointer-events": "none", opacity: ".5" } : {}} */}
                  <span data-feather="file-text" className="nav-icon"></span>
                  <span className="menu-text" style={{ cursor: "pointer" }}>
                    User List
                  </span>
                </a>
              </li>
              <li className="">
                <a
                  className={
                    window.location.href.includes("/Pollslist") ||  window.location.href.includes("/Addpolls")  || window.location.href.includes("/Editpolls")
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    AllFlage("Pollslist")
                  }}
                  style={
                    isPollsManageTrue == false
                      ? { "pointer-events": "none", opacity: ".5" }
                      : {}
                  }
                >
                  <span data-feather="bar-chart-2" className="nav-icon"></span>
                  <span className="menu-text" style={{ cursor: "pointer" }}>
                      Polls Category
                  </span>
                </a>
              </li>

              <li className="">
                <a
                  className={
                    window.location.href.includes("/Adminusermanagment") ||
                      window.location.href.includes("/AddAdmin") ||
                      window.location.href.includes("/EditAdmin")
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    AllFlage("Adminusermanagment")
                  }}
                  style={
                    isAdminUserManageTrue == false
                      ? { "pointer-events": "none", opacity: ".5" }
                      : {}
                  }
                >
                  <span data-feather="user-plus" className="nav-icon"></span>
                  <span className="menu-text" style={{ cursor: "pointer" }}>
                    Admin User Management
                  </span>
                </a>
              </li>

              <li className="">
                <a
                  className={
                    window.location.href.includes("/Inquirydetails") 
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    AllFlage("Inquirydetails")
                  }}
                  style={
                    isInquiryTrue == false
                      ? { "pointer-events": "none", opacity: ".5" }
                      : {}
                  }
                >
                  <span data-feather="phone" className="nav-icon"></span>
                  <span className="menu-text" style={{ cursor: "pointer" }}>
                    Inquiry
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </aside>
      </main>
    </>
  );
}

export default MainDashbord;
