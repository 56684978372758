import axios from "axios";
import {
  baseURL,
  IS_LOADING,
  AdminLogin_URL,
  AdminLoginReg,
  Otp_URL,
  OtpReg,
  usersList_URL,
  usersListReg,
  UpcomingTrips_URL,
  UpcomingTripReg,
  CurrentTrips_URL,
  CurrentTripsReg,
  CompletedTrips_URL,
  CompletedTripsReg,
  UndefinedTrips_URL,
  UndefinedTripsReg,
  TripListVariousCountData_URL,
  TripListVariousCountDataReg,
  subAdminListExceptLoggedInAdmin_URL,
  subAdminListExceptLoggedInAdminReg,
  addSubAdmin_URL,
  addSubAdminReg,
  editSubAdmin_URL,
  editSubAdminReg,
  deleteSubAdmin_URL,
  deleteSubAdminReg,
  accessDataByAdminId_URL,
  accessDataByAdminIdReg,
  AdminStatus_URL,
  AdminStatusReg,
  UserStatus_URL,
  UserStatusReg,
  TripDataByUserId_URL,
  TripDataByUserIdReg,
  addCategory_URL,
  addCategoryReg,
  editCategory_URL,
  editCategoryReg,
  listAllCategory_URL,
  listAllCategoryReg,
  changeCategoryStatus_URL,
  changeCategoryStatusReg,
  saveInquiry_URL,
  saveInquiryReg,
  AllInquiry_URL,
  AllInquiryReg,
  InquiryExcel_URL,
  InquiryExcelReg,
  deleteCategory_URL,
  deleteCategoryReg,
  DownloadUsersList_URL,
  DownloadUsersListReg,
  deleteUser_URL,
  deleteUserReg,
  accommodationList_URL,
  accommodationListReg,
  accommodationHotelList_URL,
  accommodationHotelListReg,
  tripDetailId,
  tripDetailsReg,
} from "../../Constant";
import swal from "sweetalert";

function getAsios() {
  let token = localStorage.getItem("token");
  let authToken = localStorage.getItem("authToken");


  if (authToken) {
    return axios.create({
      baseURL: baseURL,
      headers: {
        Authorization: authToken,
      },
    });
  } else {
    return axios.create({
      baseURL: baseURL,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
  }
}

export function dispatchLoadingStatus(loading) {
  return function (dispatch, getState) {
    dispatch({ type: IS_LOADING, payload: { loading: loading } });
  };
}

export function LoginAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(AdminLogin_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: AdminLoginReg, payload: response.data });
          localStorage.setItem("otp_id", response.data.data.otp_id);
          localStorage.setItem("admin_id", response.data.data.admin_id);

          window.location.href = window.location.origin + "/Otp";
        } else {
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function OtpAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(Otp_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: OtpReg, payload: response.data });
          localStorage.removeItem("admin_id");
          localStorage.removeItem("otp_id");
          localStorage.setItem("authToken",  response.data.authToken);
          localStorage.setItem("token", response.data.data._id);
          localStorage.setItem("nameAdmin", response.data.data.username);
          window.location.href = window.location.origin + "/Alltrip";

        } else {
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function usersListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(usersList_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: usersListReg, payload: response.data });
        } else {
          dispatch({ type: usersListReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          // swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function UpcomingTripAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(UpcomingTrips_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: UpcomingTripReg, payload: response.data });
        } else {
          dispatch({ type: UpcomingTripReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          // swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function CurrentTripAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(CurrentTrips_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: CurrentTripsReg, payload: response.data });
        } else {
          dispatch({ type: CurrentTripsReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          // swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function CompletedTripAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(CompletedTrips_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: CompletedTripsReg, payload: response.data });
        } else {
          dispatch({ type: CompletedTripsReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          // swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function UndefinedTripAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(UndefinedTrips_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        localStorage.setItem("UndefinedTripAPI", false);

        if (response.data.errorcode == 0) {
          dispatch({ type: UndefinedTripsReg, payload: response.data });
        } else {
          dispatch({ type: UndefinedTripsReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          // swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        localStorage.setItem("UndefinedTripAPI", false);

        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function TripListVariousCountDataAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(TripListVariousCountData_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({
            type: TripListVariousCountDataReg,
            payload: response.data,
          });
        } else {
          dispatch({
            type: TripListVariousCountDataReg,
            payload: response.data,
          });
          dispatch(dispatchLoadingStatus(false));
          // swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function subAdminListExceptLoggedInAdminAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(subAdminListExceptLoggedInAdmin_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        localStorage.setItem("subAdminListExceptLoggedInAdminAPI", false);

        if (response.data.errorcode == 0) {
          dispatch({
            type: subAdminListExceptLoggedInAdminReg,
            payload: response.data,
          });
        } else {
          dispatch({
            type: subAdminListExceptLoggedInAdminReg,
            payload: response.data,
          });
          dispatch(dispatchLoadingStatus(false));
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        localStorage.setItem("subAdminListExceptLoggedInAdminAPI", false);

        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function addSubAdminAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(addSubAdmin_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: addSubAdminReg, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href =
              window.location.origin + "/Adminusermanagment";
          });
        } else {
          dispatch({ type: addSubAdminReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function editSubAdminAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(editSubAdmin_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: editSubAdminReg, payload: response.data });

          swal("", response.data.message, "success").then(function () {
            window.location.href =
              window.location.origin + "/Adminusermanagment";
          });
        } else {
          dispatch({ type: editSubAdminReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function deleteSubAdminAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(deleteSubAdmin_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: deleteSubAdminReg, payload: response.data });

          //   swal("", response.data.message, "success").then(function () {
          //     window.location.href = window.location.origin + '/Adminusermanagment';

          // });
        } else {
          dispatch({ type: deleteSubAdminReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function accessDataByAdminIdAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(accessDataByAdminId_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: accessDataByAdminIdReg, payload: response.data });
        } else {
          dispatch({ type: accessDataByAdminIdReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function AdminStatusAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(AdminStatus_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        // dispatch({ type: AdminStatusReg, payload: response.data });

        if (response.data.errorcode == 0) {
          dispatch({ type: AdminStatusReg, payload: response.data });
        } else {
          dispatch({ type: AdminStatusReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function UserStatusAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(UserStatus_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        // dispatch({ type: AdminStatusReg, payload: response.data });

        if (response.data.errorcode == 0) {
          dispatch({ type: UserStatusReg, payload: response.data });
        } else {
          dispatch({ type: UserStatusReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function TripDataByUserIdAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(TripDataByUserId_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));
        // dispatch({ type: AdminStatusReg, payload: response.data });

        if (response.data.errorcode == 0) {
          dispatch({ type: TripDataByUserIdReg, payload: response.data });
        } else {
          dispatch({ type: TripDataByUserIdReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          // swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}

// 19/04/2023  Parth Roka

export function addCategoryAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(addCategory_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: addCategoryReg, payload: response.data });
          swal("", response.data.message, "success");
        } else {
          dispatch({ type: addCategoryReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function editCategoryAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(editCategory_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: editCategoryReg, payload: response.data });
          swal("", response.data.message, "success");
        } else {
          dispatch({ type: editCategoryReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function listAllCategoryAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(listAllCategory_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: listAllCategoryReg, payload: response.data });
        } else {
          dispatch({ type: listAllCategoryReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function changeCategoryStatusAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(changeCategoryStatus_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: changeCategoryStatusReg, payload: response.data });
        } else {
          dispatch({ type: changeCategoryStatusReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}




export function saveInquiryAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(saveInquiry_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: saveInquiryReg, payload: response.data });
        } else {
          dispatch({ type: saveInquiryReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}



export function AllInquiryAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(AllInquiry_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: AllInquiryReg, payload: response.data });
        } else {
          dispatch({ type: AllInquiryReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}



export function InquiryExcelAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(InquiryExcel_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: InquiryExcelReg, payload: response.data });
        } else {
          dispatch({ type: InquiryExcelReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}




export function deleteCategoryAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(deleteCategory_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: deleteCategoryReg, payload: response.data });

          //   swal("", response.data.message, "success").then(function () {
          //     window.location.href = window.location.origin + '/Adminusermanagment';

          // });
        } else {
          dispatch({ type: deleteCategoryReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
          swal("", response.data.message, "error");
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));

        swal("", "Something went wrong, please try again", "error");
      });
  };
}




export function DownloadUsersListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(DownloadUsersList_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        if (response.data.errorcode == 0) {
          dispatch({ type: DownloadUsersListReg, payload: response.data });
        } else {
          // dispatch({ type: DownloadUsersListReg, payload: response.data });
          swal("", response?.data?.message, "error");
          dispatch(dispatchLoadingStatus(false));
        }
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}



export function DeleteUserAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(deleteUser_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        
        if (response.data.errorcode == 0) {
          dispatch({ type: deleteUserReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
        } 
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}

export function accommodationListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(accommodationList_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        
        if (response.data.errorcode == 0) {
          dispatch({ type: accommodationListReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
        } 
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}
export function accommodationHotelListAPI(data) {
  return function (dispatch) {
    return getAsios()
      .post(accommodationHotelList_URL, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        
        if (response.data.errorcode == 0) {
          dispatch({ type: accommodationHotelListReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
        } 
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}



export function TripDetailsId_API(data) {
  return function (dispatch) {
    return getAsios()
      .post(tripDetailId, data)

      .then((response) => {
        dispatch(dispatchLoadingStatus(false));

        
        if (response.data.errorcode == 0) {
          dispatch({ type: tripDetailsReg, payload: response.data });
          dispatch(dispatchLoadingStatus(false));
        } 
      })
      .catch((err) => {
        dispatch(dispatchLoadingStatus(false));
        swal("", "Something went wrong, please try again", "error");
      });
  };
}





