import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import MainDashboard from "../Main Dashboard/MainDashboard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from '../Loader/Loader';
import swal from "sweetalert";
import {
  UndefinedTripAPI,
  dispatchLoadingStatus,
} from '../../API/Action/globalAction';

// Added by Moinuddin Mansuri
function UndefinedTrips() {
  const dispatch = useDispatch({});
  let history = useNavigate();

  let toggleReducer = useSelector((state) => state.globalReducer.toggle);

  let accessDataByAdminIdReg = useSelector(
    (state) => state.globalReducer.accessDataByAdminIdReg
  );

  const [access, setAccess] = useState();

  useEffect(() => {
    if (accessDataByAdminIdReg) {
      if (accessDataByAdminIdReg.errorcode == 0) {
        setAccess(
          accessDataByAdminIdReg
            ? accessDataByAdminIdReg.data
            : accessDataByAdminIdReg.data
        );
        let alldata = accessDataByAdminIdReg.data
        if (alldata.tripManage == true) {

        } else {
          history("/Alltrip")
        }
      }
    }
  }, [accessDataByAdminIdReg]);

  let isTripsReadTrue = access ? access.tripRead : "";
  let isTripsManageTrue = access ? access.tripManage : "";

  let UndefinedTripsReg = useSelector(
    (state) => state.globalReducer.UndefinedTripsReg
  );

  function AllFlage(e, i) {
    history("/" + e, { state: i });
  }

  // Api Call (Moinuddin Mansuri)
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    UndefinedTripsReg = "";
    setCounter(1);
    let token = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("token", token);
    formData.append("start", 0);
    formData.append("limit", 8);
    dispatch(UndefinedTripAPI(formData));
    dispatch(dispatchLoadingStatus(true));
  }, []);

  const [undefinedhowerIndex, setUndefinedHowerIndex] = useState(null);
  const [undefinedTrip, setUndefinedTrip] = useState("");
  const [APIcount, setAPIcount] = useState()
  const [counter, setCounter] = useState(1);
  const [trName, setTrName] = useState();

  useEffect(() => {
    if (UndefinedTripsReg) {
      if (UndefinedTripsReg.errorcode == 0) {

        let list = undefinedTrip;
        let list2 = UndefinedTripsReg.data;
        if (counter > 1) {
          var list3 = list.concat(list2);
        }
        setAPIcount(UndefinedTripsReg.count)
        if (counter > 1) {
          setUndefinedTrip(list3);
        } else {
          setUndefinedTrip(UndefinedTripsReg.data);
        }
      }
      else {
        setUndefinedTrip([]);
      }
    }
  }, [UndefinedTripsReg]);

  // Search Main Function (Moinuddin Mansuri)
  function MainSearch(key) {
    let token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("token", token);
    formData.append("name", trName ? trName : "");
    formData.append("start", key);
    formData.append("limit", 8);
    dispatch(UndefinedTripAPI(formData));
  }

  useEffect(() => {
    if (trName) {
      MainSearch(0);
      return;
    }

    if (trName <= 0) {
      MainSearch(0);
      return;
    }
  }, [trName]);


  // Add More Data (Moinuddin Mansuri)
  const handleMoreData = () => {
    setCounter(counter + 1);
    let token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("token", token);
    formData.append("name", trName ? trName : "");
    formData.append("start", counter * 8);
    formData.append("limit", 8);
    dispatch(UndefinedTripAPI(formData));
  };
  return (
    <>
      <Loader />
      <div className={toggleReducer == false ? "contents expanded" : "contents"}>
        <div className="crm">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-main">
                  <h4 className="text-capitalize breadcrumb-title">
                    Undefined Trips
                  </h4>
                  <div className="breadcrumb-action justify-content-center flex-wrap">
                    <div className=''> <ul className="atbd-breadcrumb nav">
                      <li class="atbd-breadcrumb__item">
                      </li>
                      <li className="atbd-breadcrumb__item">
                        <a href="/Alltrip">
                          Trips
                        </a>
                        <span className="breadcrumb__seperator">
                          <span className="la la-slash"></span>
                        </span>
                      </li>
                      <li className="atbd-breadcrumb__item">
                        <a href="/Undefinedtrips">Undefined Trips</a>
                        <span className="breadcrumb__seperator">
                        </span>
                      </li>
                    </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 offset-8">
                <div className="action-btn mb-3">
                  <div className='with-icon'>
                    <span className='fa fa-search'></span>
                    <input
                      className="form-control mr-sm-2 box-shadow-none"
                      type="search"
                      placeholder="Search trips"
                      aria-label="Search"
                      maxLength={30}
                      value={trName}
                      onChange={(e) => { setTrName(e.target.value.trim() == "" ? "" : e.target.value); setCounter(1) }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-12">
                <div className="card card-default card-md mb-4">
                  <div className="card-body">
                    <div className="slider-card-main row">
                      {undefinedTrip
                        ? undefinedTrip.length > 0
                          ? undefinedTrip.map((i, index) => (
                            <div className="car col-md-3 mb-4" key={i._id} onMouseOver={() => setUndefinedHowerIndex(index)} onMouseOut={() => setUndefinedHowerIndex(null)}>
                              <div className="card">
                                <div className="card-body">
                                  <div className="card-content">
                                    <div className="card-title" >

                                      {undefinedhowerIndex == index ? i.name :
                                        i.name.length > 9 ? i.name.slice(0, 9) + "..." : i.name}
                                    </div>
                                  </div>
                                  <img
                                    src={i.coverPic}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (isTripsReadTrue == true && isTripsManageTrue == true) {
                                        AllFlage("Tripdetails", i);
                                      } else {
                                        swal({
                                          text: "you don't have permission",
                                          icon: "warning",
                                          dangerMode: true,
                                        })
                                      }
                                    }}
                                  />
                                  <div id="" className="middle">
                                    <div className="text">Slide 1</div>
                                  </div>

                                  <div className="card-content">
                                    <div className="card-title font-weight-bold">
                                      {i.tripCreatedBy}
                                    </div>
                                    <div style={{ whiteSpace: "nowrap" }}>
                                      <i className="color-gold fa fa-crown"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                          : ""
                        : ""}
                    </div>
                    {undefinedTrip ? (
                      undefinedTrip.length > 0 ? null : (
                        <div style={{ textAlign: "center", fontWeight: "500", color: "#000", marginTop: "18px", }}>
                          <p>No data found</p>{" "}
                        </div>
                      )
                    ) : null}

                    {UndefinedTripsReg
                      && undefinedTrip.length < APIcount
                      ? (
                        UndefinedTripsReg.count > 8 ? (
                          <div className="col-lg-12 mt-4 ">
                            <button
                              onClick={() => handleMoreData()}
                              className="view-all btn btn-primary text-center m-auto"
                            >
                              View More
                            </button>
                          </div>
                        ) : null
                      ) : null}
                  </div>
                </div>
              </div>
              <MainDashboard />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UndefinedTrips;
