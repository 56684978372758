
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { dispatchLoadingStatus, TripDataByUserIdAPI, accessDataByAdminIdAPI, DeleteUserAPI } from '../../API/Action/globalAction';
import swal from "sweetalert";
import { confirmAlert } from "react-confirm-alert";
import Footer from "../Footer/Footer";
import MainDashboard from "../Main Dashboard/MainDashboard";

function UserDetails() {
  const dispatch = useDispatch({});
  let history = useNavigate();
  let toggleReducer = useSelector((state) => state.globalReducer.toggle);

  useEffect(() => {
    deleteUserReg = ""
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    let token = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("token", token);
    formData.append("user_id", state ? role ? state.memberId : state._id : "");
    formData.append("start", 0);
    formData.append("limit", 4);
    dispatch(dispatchLoadingStatus(true));
    dispatch(TripDataByUserIdAPI(formData));
    dispatch(accessDataByAdminIdAPI(formData));
  }, []);

  let TripDataByUserIdReg = useSelector((state) => state.globalReducer.TripDataByUserIdReg);

  let accessDataByAdminIdReg = useSelector(
    (state) => state.globalReducer.accessDataByAdminIdReg
  );

  const [access, setAccess] = useState();

  useEffect(() => {
    if (accessDataByAdminIdReg) {
      if (accessDataByAdminIdReg.errorcode == 0) {
        setAccess(
          accessDataByAdminIdReg
            ? accessDataByAdminIdReg.data
            : accessDataByAdminIdReg.data
        );
        let alldata = accessDataByAdminIdReg.data
        if (alldata.userManage == true && alldata.userRead == true) {
        } else {
          history("/Alltrip")
        }
      }
    }
  }, [accessDataByAdminIdReg]);

  let isTripsReadTrue = access ? access.tripRead : "";
  let isTripsManageTrue = access ? access.tripManage : "";
  const { state } = useLocation("");
  const [counter, setCounter] = useState(1);
  const [APIcount, setAPIcount] = useState();
  const [UserhowerIndex, setUserUndefinedHowerIndex] = useState(null);
  const [AllTrip, setAllTrip] = useState("");

  useEffect(() => {
    if (TripDataByUserIdReg) {
      if (TripDataByUserIdReg.errorcode == 0) {
        let list = AllTrip;
        let list2 = TripDataByUserIdReg.data;
        if (counter > 1) {
          var list3 = list.concat(list2);
        }
        setAPIcount(TripDataByUserIdReg.count);
        if (counter > 1) {
          setAllTrip(list3);
        } else {
          setAllTrip(TripDataByUserIdReg.data);
        }
      } else {
        setAllTrip([]);
      }
    }
  }, [TripDataByUserIdReg]);

  let role = state ? state.role : ""
  let deleteUserReg = useSelector((state) => state.globalReducer.deleteUserReg);

  useEffect(() => {
    if (deleteUserReg) {
      if (deleteUserReg.errorcode == 0) {
        if (role != "" || role != "undefined" || role != null) {
          history("/Userlist");
        } else {
          history("/Alltrip");
        }
      }
    }
  }, [deleteUserReg]);

  function AllFlage(e, i) {
    history("/" + e, { state: i });
  }

  const handleMoreData = () => {
    setCounter(counter + 1);
    let token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("token", token);
    formData.append("user_id", state ? role ? state.memberId : state._id : "");
    formData.append("start", counter * 4);
    formData.append("limit", 4);
    dispatch(TripDataByUserIdAPI(formData));
  };

  const HandleUserDelete = () => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: "Are you sure you want to delete User?",
      buttons: [
        {
          label: "Cancel",
          onClick: () => { },
        },
        {
          label: "Ok",
          onClick: () => {
            let userId = state ? role ? state.memberId : state._id : ""
            var formData = new FormData();
            formData.append("user_id", userId);
            dispatch(DeleteUserAPI(formData));
          },
        },
      ],
    });
  }

  return (
    <>
      <div className="mobile-search"></div>
      <div className="mobile-author-actions"></div>
      <div className={toggleReducer == false ? "contents expanded" : "contents"}>
        <div className="crm">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-main">
                  <h4 className="text-capitalize breadcrumb-title mt-3">User Details</h4>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-xl-4 col-md-8 col-sm-10">
                <div className="card order-summery order-summery--width ml-0  bg-normal p-sm-25 p-15 w-100">
                  <div className="card-header border-bottom-0 p-0 pb-25 user-profile-card-header">
                    <h5 className="fw-500">User Profile</h5>
                    <button
                      onClick={() => HandleUserDelete()}
                      className="border-0"
                    >
                      <i class="fa fa-trash" ></i>
                    </button>
                  </div>
                  <div className="card-body bg-white">
                    <div className="total">
                      <div className="subtotalTotal d-flex">
                        <p>Name:</p>
                        <p style={{ wordBreak: "break-all" }} className="ml-1">{state ? role ? state.memberName : state.firstName + " " + state.lastName : ""}</p>
                      </div>
                      <div className="taxes d-flex">
                        <p>Email:</p>
                        <p style={{ wordBreak: "break-all" }} className="ml-1">{state ? role ? state.memberEmail : state.email : ""}</p>
                      </div>
                      <div className="shipping d-flex">
                        <p>Phone Number:</p>
                        <p style={{ wordBreak: "break-all" }} className='ml-1'>{state ? role ? state.memberPhone : state.phone : "-"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card card-default card-md mt-2 mb-2">
                  <div className="card-header">
                    <h6>User's Trips</h6>
                  </div>
                  <div className="card-body scrolling-card scroll-timeline timeline-box--3" style={{ justifyContent: "center", alignItems: "center" }}>
                    <div className="slider-card-main row">
                      {AllTrip
                        ? AllTrip.length > 0
                          ? AllTrip.map((i, index) => (
                            <div className="car col-md-6 mb-4" key={i._id} onMouseOver={() => setUserUndefinedHowerIndex(index)} onMouseOut={() => setUserUndefinedHowerIndex(null)}>
                              <div className="card">
                                <div className="card-body">
                                  <div className="card-content">
                                    <div className="card-title" >
                                      {UserhowerIndex == index ? i.name :
                                        i.name.length > 9 ? i.name.slice(0, 9) + "..." : i.name}
                                    </div>
                                  </div>
                                  <img
                                    src={i.coverPic}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (isTripsReadTrue == true && isTripsManageTrue == true) {
                                        AllFlage("Tripdetails", i);
                                      } else {
                                        swal({
                                          text: "you don't have permission",
                                          icon: "warning",
                                          dangerMode: true,
                                        })
                                      }
                                    }}
                                  />
                                  <div id="" className="middle">
                                    <div className="text">Slide 1</div>
                                  </div>
                                  <div className="card-content">
                                    <div className="card-title font-weight-bold">
                                      {i.tripCreatedBy}
                                    </div>
                                    <div style={{ whiteSpace: "nowrap" }}>
                                      <i className="color-gold fa fa-crown"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                          : ""
                        : ""}
                    </div>
                    {AllTrip ? (
                      AllTrip.length > 0 ? null : (
                        <div style={{ textAlign: "center", fontWeight: "500", color: "#000", }}>
                          <p>User have no trips at the moment.</p>
                        </div>
                      )
                    ) : null}
                    {TripDataByUserIdReg && AllTrip.length < APIcount ? (
                      TripDataByUserIdReg.count >= 2 ? (
                        <div className="col-lg-12 mt-4 ">
                          <button
                            onClick={() => handleMoreData()}
                            className="view-all btn btn-primary text-center m-auto"
                          >
                            View More
                          </button>
                        </div>
                      ) : null
                    ) : null}
                  </div>
                </div>
                <MainDashboard />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}


export default UserDetails;