import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import MainDashboard from "../Main Dashboard/MainDashboard";
import Loader from '../Loader/Loader';
import swal from "sweetalert";

import {
  UpcomingTripAPI,
  dispatchLoadingStatus,
  CurrentTripAPI,
  CompletedTripAPI,
  UndefinedTripAPI,
  TripListVariousCountDataAPI,
} from '../../API/Action/globalAction';

// Added By Moinuddin Mansuri
function AllTrip() {
  const dispatch = useDispatch({});
  let history = useNavigate();

  let toggleReducer = useSelector((state) => state.globalReducer.toggle);


  let accessDataByAdminIdReg = useSelector(
    (state) => state.globalReducer.accessDataByAdminIdReg
  );

  const [access, setAccess] = useState();

  useEffect(() => {
    if (accessDataByAdminIdReg) {
      if (accessDataByAdminIdReg.errorcode == 0) {
        setAccess(
          accessDataByAdminIdReg
            ? accessDataByAdminIdReg.data
            : accessDataByAdminIdReg.data
        );

        let alldata = accessDataByAdminIdReg.data
        if (alldata.tripManage == true) {

        } else {
          history("/Alltrip")
        }
      }
    }
  }, [accessDataByAdminIdReg]);

  let isTripsReadTrue = access ? access.tripRead : "";
  let isTripsManageTrue = access ? access.tripManage : "";

  const UpcomingTripReg = useSelector(
    (state) => state.globalReducer.UpcomingTripReg
  );

  const CurrentTripsReg = useSelector(
    (state) => state.globalReducer.CurrentTripsReg
  );

  const CompletedTripsReg = useSelector(
    (state) => state.globalReducer.CompletedTripsReg
  );

  const UndefinedTripsReg = useSelector(
    (state) => state.globalReducer.UndefinedTripsReg
  );

  const TripListVariousCountDataReg = useSelector(
    (state) => state.globalReducer.TripListVariousCountDataReg
  );

  function AllFlage(e, i) {

    history("/" + e, { state: i });

  }


  // api call (Moinuddin Mansuri)

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });

    let token = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("token", token);
    formData.append("start", 0);
    formData.append("limit", 4);
    dispatch(dispatchLoadingStatus(true));
    dispatch(UpcomingTripAPI(formData));
    dispatch(CurrentTripAPI(formData));
    dispatch(CompletedTripAPI(formData));
    dispatch(UndefinedTripAPI(formData));
    dispatch(TripListVariousCountDataAPI(formData));
  }, []);


  const [TripListVariousCount, setTripListVariousCount] = useState();

  useEffect(() => {
    if (TripListVariousCountDataReg) {
      if (TripListVariousCountDataReg.errorcode == 0) {
        setTripListVariousCount(
          TripListVariousCountDataReg ? TripListVariousCountDataReg.data : TripListVariousCountDataReg.data
        );
      } else {
        setTripListVariousCount([]);
      }
    }
  }, [TripListVariousCountDataReg]);

  const [upcomingTrip, setUpcomingTrip] = useState();
  const [upcominghowerIndex, setupcomingHowerIndex] = useState(null);

  useEffect(() => {
    if (UpcomingTripReg) {
      if (UpcomingTripReg.errorcode == 0) {
        setUpcomingTrip(
          UpcomingTripReg ? UpcomingTripReg.data : UpcomingTripReg.data
        );
      } else {
        setUpcomingTrip([]);
      }
    }
  }, [UpcomingTripReg]);

  const [currentTrip, setCurrentTrip] = useState();
  const [currenthowerIndex, setcurrentHowerIndex] = useState(null);

  useEffect(() => {
    if (CurrentTripsReg) {
      if (CurrentTripsReg.errorcode == 0) {
        setCurrentTrip(
          CurrentTripsReg ? CurrentTripsReg.data : CurrentTripsReg.data
        );
      } else {
        setCurrentTrip([]);
      }
    }
  }, [CurrentTripsReg]);

  const [completedhowerIndex, setcompletedHowerIndex] = useState(null);
  const [completedTrip, setCompletedTrip] = useState();

  useEffect(() => {
    if (CompletedTripsReg) {
      if (CompletedTripsReg.errorcode == 0) {
        setCompletedTrip(
          CompletedTripsReg ? CompletedTripsReg.data : CompletedTripsReg.data
        );
      } else {
        setCompletedTrip([]);
      }
    }
  }, [CompletedTripsReg]);

  const [undefinedhowerIndex, setUndefinedHowerIndex] = useState(null);

  const [undefinedTrip, setUndefinedTrip] = useState();

  useEffect(() => {
    if (UndefinedTripsReg) {
      if (UndefinedTripsReg.errorcode == 0) {
        setUndefinedTrip(
          UndefinedTripsReg ? UndefinedTripsReg.data : UndefinedTripsReg.data
        );
      } else {
        setUndefinedTrip([]);
      }
    }
  }, [UndefinedTripsReg]);


  return (
    <>
      <Loader />
      <div className={toggleReducer == false ? "contents expanded" : "contents"} >
        <div className="crm">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">


                <div className="breadcrumb-main mt-5">
                  <h4 className="text-capitalize breadcrumb-title">Trips</h4>
                  <div className="breadcrumb-action justify-content-center flex-wrap">
                    <div className=''> <ul className="atbd-breadcrumb nav">
                      <li class="atbd-breadcrumb__item">

                      </li>
                      <li className="atbd-breadcrumb__item">
                        <a href="/Alltrip" className="ml-2">
                          Trips
                        </a>
                      </li>
                    </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg col-6 mb-30">
                <div className="ap-po-details ap-po-details--2 text-center p-25 radius-xl bg-primary justify-content-between">
                  <div>
                    <div className="overview-content">
                      <h1 className="text-white">{TripListVariousCount ? TripListVariousCount.totalTrips : ""}</h1>
                      <p className="text-white mb-0">Total Trips</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg col-6  mb-30">
                <div className="ap-po-details ap-po-details--2 text-center p-25 radius-xl bg-secondary  justify-content-between">
                  <div>
                    <div className="overview-content">
                      <h1 className="text-white">{TripListVariousCount ? TripListVariousCount.currentTrips : ""}</h1>
                      <p className="text-white mb-0">Current Trips</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg col-6  mb-30">
                <div className="ap-po-details ap-po-details--2 text-center p-25 radius-xl bg-success  justify-content-between">
                  <div>
                    <div className="overview-content">
                      <h1 className="text-white">{TripListVariousCount ? TripListVariousCount.completedTrips : ""}</h1>
                      <p className="text-white mb-0">Completed Trips</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg col-6  mb-30">
                <div className="ap-po-details ap-po-details--2 text-center p-25 radius-xl bg-info justify-content-between">
                  <div>
                    <div className="overview-content">
                      <h1 className="text-white">{TripListVariousCount ? TripListVariousCount.activeTravelers : ""}</h1>
                      <p className="text-white mb-0">Active Travellers</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg col-6  mb-30">
                <div className="ap-po-details ap-po-details--2 text-center p-25 radius-xl bg-warning justify-content-between">
                  <div>
                    <div className="overview-content">
                      <h1 className="text-white">{TripListVariousCount ? TripListVariousCount.totalUsers : ""}</h1>
                      <p className="text-white mb-0">Total Users</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-12">
                <div className="card card-default card-md mb-4">
                  <div className="card-header">
                    <h6>Current Trips</h6>
                  </div>
                  <div className="card-body">
                    <div className="slider-card-main row">
                      {currentTrip
                        ? currentTrip.map((i, index) => (
                          <div className="car col-md-3" key={i._id} onMouseOver={() => setcurrentHowerIndex(index)} onMouseOut={() => setcurrentHowerIndex(null)}>
                            <div className="card">
                              <div className="card-body">
                                <div className="card-content">
                                  <div className="card-title ">
                                    {currenthowerIndex == index ? i.name :
                                      i.name.length > 9 ? i.name.slice(0, 9) + "..." : i.name}
                                    {/* {i.name} */}
                                  </div>

                                </div>
                                <img
                                  src={i.coverPic}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (isTripsReadTrue == true && isTripsManageTrue == true) {
                                      AllFlage("Tripdetails", i);
                                    } else {
                                      swal({
                                        text: "you don't have permission",
                                        icon: "warning",
                                        dangerMode: true,
                                      })
                                    }
                                  }}
                                />
                                <div id="" className="middle">
                                  <div className="text">Slide 1</div>
                                </div>

                                <div className="card-content">
                                  <div className="card-title font-weight-bold">
                                    <p>{i.tripCreatedBy}</p>

                                  </div>
                                  <div style={{ whiteSpace: "nowrap" }}>
                                    <i className="color-gold fa fa-crown"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                        : ""}
                      {currentTrip ? currentTrip.length > 0 ? null : <div className="col-lg-12"  ><p className="text-center">No Current Trip Found</p> </div> : null}

                    </div>
                    {currentTrip ? currentTrip.length >= 4 ?
                      <div className="col-lg-12 mt-4 ">
                        <button
                          onClick={() => {
                            AllFlage("Currenttrips");

                          }}
                          className="view-all btn btn-primary text-center m-auto"
                        >
                          View All
                        </button>
                      </div> : "" : ""}
                  </div>
                </div>
              </div>
              {/* Upcoming trips */}

              <div className="col-lg-12">
                <div className="card card-default card-md mb-4">
                  <div className="card-header">
                    <h6>Upcoming Trips</h6>
                  </div>
                  <div className="card-body">
                    <div className="slider-card-main row">
                      {upcomingTrip
                        ? upcomingTrip.map((i, index) => (
                          <div className="car col-md-3" key={i._id} onMouseOver={() => setupcomingHowerIndex(index)} onMouseOut={() => setupcomingHowerIndex(null)}>
                            <div className="card">
                              <div className="card-body">
                                <div className="card-content">
                                  <div className="card-title ">
                                    {/* {i.name} */}
                                    {upcominghowerIndex == index ? i.name :
                                      i.name.length > 9 ? i.name.slice(0, 9) + "..." : i.name}

                                  </div>
                                </div>
                                <img
                                  src={i.coverPic}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (isTripsReadTrue == true && isTripsManageTrue == true) {
                                      AllFlage("Tripdetails", i);
                                    } else {
                                      swal({
                                        text: "you don't have permission",
                                        icon: "warning",
                                        dangerMode: true,
                                      })
                                    }
                                  }}
                                />
                                <div id="" className="middle">
                                  <div className="text">Slide 1</div>
                                </div>

                                <div className="card-content">
                                  <div className="card-title font-weight-bold">
                                    {i.tripCreatedBy}

                                  </div>
                                  <div style={{ whiteSpace: "nowrap" }}>
                                    <i className="color-gold fa fa-crown"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                        : ""}

                      {upcomingTrip ? upcomingTrip.length > 0 ? null : <div className="col-lg-12"><p className="text-center">No Upcoming Trip Found</p> </div> : null}

                    </div>
                    {upcomingTrip ? upcomingTrip.length >= 4 ?
                      <div className="col-lg-12 mt-4 ">
                        <button
                          onClick={() => {
                            AllFlage("Upcomingtrips");

                          }}
                          className="view-all btn btn-primary text-center m-auto"
                        >
                          View All
                        </button>
                      </div> : "" : ""}
                  </div>
                </div>
              </div>

              {/* Completed Trips */}

              <div className="col-lg-12">
                <div className="card card-default card-md mb-4">
                  <div className="card-header">
                    <h6>Completed Trips</h6>
                  </div>
                  <div className="card-body">
                    <div className="slider-card-main row">
                      {completedTrip
                        ? completedTrip.map((i, index) => (
                          <div className="car col-md-3" key={i._id} onMouseOver={() => setcompletedHowerIndex(index)} onMouseOut={() => setcompletedHowerIndex(null)}>
                            <div className="card">
                              <div className="card-body">
                                <div className="card-content">
                                  <div className="card-title ">
                                    {/* {i.name} */}
                                    {completedhowerIndex == index ? i.name :
                                      i.name.length > 9 ? i.name.slice(0, 9) + "..." : i.name}
                                  </div>

                                </div>
                                <img
                                  src={i.coverPic}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (isTripsReadTrue == true && isTripsManageTrue == true) {
                                      AllFlage("Tripdetails", i);
                                    } else {
                                      swal({
                                        text: "you don't have permission",
                                        icon: "warning",
                                        dangerMode: true,
                                      })
                                    }
                                  }}
                                />
                                <div id="" className="middle">
                                  <div className="text">Slide 1</div>
                                </div>

                                <div className="card-content">
                                  <div className="card-title font-weight-bold">
                                    {i.tripCreatedBy}
                                  </div>
                                  <div>
                                    <i className="fa fa-crown color-gold"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                        : ""}

                      {completedTrip ? completedTrip.length > 0 ? null : <div className="col-lg-12" ><p className="text-center">No Completed Trip Found</p> </div> : null}

                    </div>

                    {completedTrip ? completedTrip.length >= 4 ?
                      <div className="col-lg-12 mt-4 ">
                        <button
                          onClick={() => {
                            AllFlage("Completedtrips");

                          }}
                          className="view-all btn btn-primary text-center m-auto"
                        >
                          View All
                        </button>
                      </div> : "" : ""}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-default card-md mb-4">
                  <div className="card-header">
                    <h6>Undefined Trips</h6>
                  </div>
                  <div className="card-body">
                    <div className="slider-card-main row">
                      {undefinedTrip
                        ? undefinedTrip.map((i, index) => (
                          <div className="car col-md-3" key={i._id} onMouseOver={() => setUndefinedHowerIndex(index)} onMouseOut={() => setUndefinedHowerIndex(null)}>
                            <div className="card">
                              <div className="card-body">
                                <div className="card-content">
                                  <div className="card-title" >

                                    {undefinedhowerIndex == index ? i.name :
                                      i.name.length > 9 ? i.name.slice(0, 9) + "..." : i.name}
                                  </div>
                                </div>
                                <img
                                  src={i.coverPic}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (isTripsReadTrue == true && isTripsManageTrue == true) {
                                      AllFlage("Tripdetails", i);
                                    } else {
                                      swal({
                                        text: "you don't have permission",
                                        icon: "warning",
                                        dangerMode: true,
                                      })
                                    }
                                  }}

                                />
                                <div id="" className="middle">
                                  <div className="text">Slide 1</div>
                                </div>

                                <div className="card-content">
                                  <div className="card-title font-weight-bold">
                                    {i.tripCreatedBy}
                                  </div>
                                  <div style={{ whiteSpace: "nowrap" }}>
                                    <i className="color-gold fa fa-crown"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                        : ""}

                      {undefinedTrip ? undefinedTrip.length > 0 ? null : <div className="col-lg-12"><p className="text-center">No Undefined Trip Found</p> </div> : null}

                    </div>
                    {undefinedTrip ? undefinedTrip.length >= 4 ?
                      <div className="col-lg-12 mt-4 ">
                        <button
                          onClick={() => {
                            AllFlage("Undefinedtrips");

                          }}
                          className="view-all btn btn-primary text-center m-auto"
                        >
                          View All
                        </button>
                      </div>
                      : "" : ""}
                  </div>
                </div>
              </div>
              <MainDashboard />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AllTrip;
