import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import MainDashboard from "../Main Dashboard/MainDashboard";

import {
  UpcomingTripAPI,
  dispatchLoadingStatus,
} from '../../API/Action/globalAction';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import Loader from '../Loader/Loader';
import swal from "sweetalert";

// Added by Moinuddin Mansuri
function UpcomingTrips() {
  const dispatch = useDispatch({});
  let history = useNavigate();
  const startdatePickerRef = useRef(null);
  const enddatePickerRef = useRef(null);

  let accessDataByAdminIdReg = useSelector(
    (state) => state.globalReducer.accessDataByAdminIdReg
  );

  const [access, setAccess] = useState();

  useEffect(() => {
    if (accessDataByAdminIdReg) {
      if (accessDataByAdminIdReg.errorcode == 0) {
        setAccess(
          accessDataByAdminIdReg
            ? accessDataByAdminIdReg.data
            : accessDataByAdminIdReg.data
        );
        let alldata = accessDataByAdminIdReg.data
        if (alldata.tripManage == true) {
          
        } else {
          history("/Alltrip")
        }
      }
    }
  }, [accessDataByAdminIdReg]);

  let isTripsReadTrue = access ? access.tripRead  : "";
  let isTripsManageTrue = access ? access.tripManage  : "";
  let toggleReducer = useSelector((state) => state.globalReducer.toggle);

  let UpcomingTripReg = useSelector(
    (state) => state.globalReducer.UpcomingTripReg
  );

  function AllFlage(e, i) {
    history("/" + e, { state: i });
  }

  // Api Call (Moinuddin Mansuri)
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    UpcomingTripReg = "";
    setCounter(1);
    let token = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("token", token);
    formData.append("start", 0);
    formData.append("limit", 8);
    dispatch(dispatchLoadingStatus(true));
    dispatch(UpcomingTripAPI(formData));
  }, []);


  const [upcominghowerIndex, setupcomingHowerIndex] = useState(null);


  const [upcomingTrip, setUpcomingTrip] = useState("");
  const [APIcount, setAPIcount] = useState()
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    if (UpcomingTripReg) {
      if (UpcomingTripReg.errorcode == 0) {
        let list = upcomingTrip;
        let list2 = UpcomingTripReg.data;
        if (counter > 1) {
          var list3 = list.concat(list2);
        }
        setAPIcount(UpcomingTripReg.count);
        if (counter > 1) {
          setUpcomingTrip(list3);
        } else {
          setUpcomingTrip(UpcomingTripReg.data);
        }
      } else {
        setUpcomingTrip([]);
      }
    }
  }, [UpcomingTripReg]);


  // Search Main Function (Moinuddin Mansuri)
  function MainSearch(key) {
    let token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("token", token);
    formData.append("name", trName ? trName : "");
    formData.append("destination", desName ? desName : "");
    formData.append(
      "start_date",
      startDate ? moment(startDate).format("MM/DD/YYYY") : ""
    );
    formData.append(
      "end_date",
      endDate ? moment(endDate).format("MM/DD/YYYY") : ""
    );
    formData.append("transportationType", transport ? transport : "");
    formData.append("start", key);
    formData.append("limit", 8);

    dispatch(UpcomingTripAPI(formData));
  }

  const [trName, setTrName] = useState();
  useEffect(() => {
    if (trName) {
      MainSearch(0);
      return;
    }

    if (trName <= 0) {
      MainSearch(0);
      return;
    }
  }, [trName]);

  const [desName, setDesName] = useState();
  useEffect(() => {
    if (desName) {
      MainSearch(0);
      return;
    }

    if (desName <= 0) {
      MainSearch(0);
      return;
    }
  }, [desName]);

  const [startDate, setStartDate] = useState();

  const openDatePicker = (e) => {
    if (e == "startDate") {
      if (startdatePickerRef && startdatePickerRef.current) {
        startdatePickerRef.current.setOpen(true);
      }
    } else {
      if (enddatePickerRef && enddatePickerRef.current) {
        enddatePickerRef.current.setOpen(true);
      }
    }
  };

  useEffect(() => {
    if (startDate) {
      MainSearch(0);
      return;
    }

    if (startDate <= 0) {
      MainSearch(0);
      return;
    }
  }, [startDate]);

  const [endDate, setEndDate] = useState();
  useEffect(() => {
    if (endDate) {
      MainSearch(0);
      return;
    }

    if (endDate <= 0) {
      MainSearch(0);
      return;
    }
  }, [endDate]);

  const [transport, setTransport] = useState(null);

  useEffect(() => {
    if (transport) {
      MainSearch(0)
      return
    }
    if (!transport) {
      MainSearch(0)
      return
    }

  }, [transport])

  // Add More Data (Moinuddin Mansuri)
  const handleMoreData = () => {
    setCounter(counter + 1);
    let token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("token", token);
    formData.append("name", trName ? trName : "");
    formData.append("destination", desName ? desName : "");
    formData.append("start_date", startDate ? moment(startDate).format("MM/DD/YYYY") : "");
    formData.append("end_date", endDate ? moment(endDate).format("MM/DD/YYYY") : "");
    formData.append("transportationType", transport ? transport : "");
      formData.append("start",counter * 8);
      formData.append("limit", 8);
    dispatch(UpcomingTripAPI(formData));
  };

  return (
    <>
      <Loader />
      <div className={toggleReducer == false ? "contents expanded" : "contents"}>
        <div className="crm">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-main">
                  <h4 className="text-capitalize breadcrumb-title">
                    Upcoming Trips
                  </h4>
                  <div className="breadcrumb-action justify-content-center flex-wrap">
                    <div className=''> <ul className="atbd-breadcrumb nav">
                    <li class="atbd-breadcrumb__item">
</li>
                      <li className="atbd-breadcrumb__item">
                        <a href="/Alltrip">
                          Trips
                        </a>
                        <span className="breadcrumb__seperator">
                          <span className="la la-slash"></span>
                        </span>
                      </li>
                      <li className="atbd-breadcrumb__item">
                        <a href="/Upcomingtrips">Upcoming Trips</a>
                        <span className="breadcrumb__seperator">
                        </span>
                      </li>
                    </ul>
                    </div>
                  </div>
                  <div className="breadcrumb-action flex-wrap">
                    <div className="action-btn custom-select-1">
                    </div>
                    <div className="action-btn">
                        <div className='with-icon'>
                          <span className='fa fa-search'></span>
                          <input
                            className="form-control mr-sm-2 box-shadow-none"
                            type="search"
                            placeholder="name"
                            aria-label="Search"
                            maxLength={20}
                            onChange={(e) => { setTrName(e.target.value.trim() == "" ? "" : e.target.value); setCounter(1) }}
                            value={trName}
                          />
                        </div>
                    </div>
                    <div className="action-btn">
                        <div className='with-icon'>
                          <span className='fa fa-search'></span>
                          <input
                            className="form-control mr-sm-2 box-shadow-none"
                            type="search"
                            placeholder="destination"
                            aria-label="Search"
                            maxLength={20}
                            onChange={(e) => { setDesName(e.target.value.trim() == "" ? "" : e.target.value); setCounter(1) }}
                            value={desName}
                          />
                        </div>
                    </div>
                    <div className="action-btn">
                      <div className="atbd-date-picker">
                        <div className="form-group mb-0 form-group-calender">
                          <div className="position-relative curr-trip-date-new">
                            <DatePicker
                              name="Startdate"
                              className="form-control form-control-default"
                              placeholderText={"mm/dd/yyyy"}
                              dateFormat="MM/dd/yyyy"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              minDate={moment("01/01/1947").toDate()}
                              onChange={(e) => { setStartDate(e); setCounter(1) }}
                              selected={startDate}
                              maxDate={endDate ? new Date(endDate) : new Date()}
                              ref={startdatePickerRef}
                              autoComplete="off"
                                isClearable={true}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                              }}
                            />

                            <a onClick={() => openDatePicker("startDate")}>
                              <span data-feather="calendar"></span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="action-btn">
                      <div className="atbd-date-picker">
                        <div className="form-group mb-0 form-group-calender">
                          <div className="position-relative curr-trip-date-new">
                            <DatePicker
                              name="Enddate"
                              className="form-control form-control-default"
                              placeholderText={"mm/dd/yyyy"}
                              dateFormat="MM/dd/yyyy"
                              onChange={(e) => { setEndDate(e); setCounter(1) }}
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              minDate={startDate ? new Date(startDate) : null}
                              selected={endDate}
                              maxDate={new Date()}
                              ref={enddatePickerRef}
                              autoComplete="off"
                                isClearable={true}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                              }}
                            />
                            <a onClick={() => openDatePicker("EndDate")} >
                              <span data-feather="calendar"></span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-lg-12">
                <div className="card card-default card-md mb-4">
                  <div className="card-body">
                    <div className="slider-card-main row">
                      {upcomingTrip ? upcomingTrip.length > 0
                        ? upcomingTrip.map((i, index) => (
                          <div className="car col-md-3 mb-4" key={i._id} onMouseOver = {() => setupcomingHowerIndex(index)} onMouseOut = { () => setupcomingHowerIndex(null)} >
                            <div className="card">
                              <div className="card-body">
                              <div className="card-content">
                                    <div className="card-title ">
                                      {upcominghowerIndex == index ? i.name :
                                      i.name.length > 9 ? i.name.slice(0, 9) + "..." : i.name}
                                      </div>
                                    </div>
                                <img
                                  src={i.coverPic}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => { 
                                    if (isTripsReadTrue == true && isTripsManageTrue == true) {
                                      AllFlage("Tripdetails", i);
                                    } else {
                                      swal({
                                        text: "you don't have permission",
                                        icon: "warning",
                                        dangerMode: true,
                                      })
                                    }
                                  }}
                                />
                                <div id="" className="middle">
                                  <div className="text">Slide 1</div>
                                </div>
                                <div className="card-content">
                                    <div className="card-title font-weight-bold">{i.tripCreatedBy}</div>
                                    <div>
                                        <i className="color-gold fa fa-crown"></i>
                                      </div>
                                    </div>
                              </div>
                            </div>
                          </div>
                        ))
                        : "" : ""}
                    </div>
                    {upcomingTrip ? upcomingTrip.length > 0 ? null : <div style={{ textAlign: "center", fontWeight: "500", color: "#000", marginTop: "18px", }} ><p>No data found</p> </div> : null}
                    
                    {UpcomingTripReg
                      && upcomingTrip.length < APIcount
                      ? (
                        UpcomingTripReg.count > 8 ? (
                          <div className="col-lg-12 mt-4 ">
                            <button
                              onClick={() => handleMoreData()}
                              className="view-all btn btn-primary text-center m-auto"
                            >
                              View More
                            </button>
                          </div>
                        ) : null
                      ) : null}
                  </div>
                </div>
              </div>
              <MainDashboard />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UpcomingTrips;
