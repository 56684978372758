import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom"
import { LoginAPI, OtpAPI, dispatchLoadingStatus } from '../../API/Action/globalAction';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader/Loader';
import logoWhite from "../../assets/Images/logo-white.svg"

// Added by Parth Roka
function Otp() {

    const [counter, setTimer] = useState('00:00');
    const [One, setOne] = useState("");
    const [Two, setTwo] = useState("");
    const [Three, setThree] = useState("");
    const [Four, setFour] = useState("");
    const [Click, setClick] = useState(1);

    // Auto Focus input field (Parth Roka)
    function Move(e, p, c, n) {

        var length = document.getElementById(c).value.length;
        var maxLength = document.getElementById(c).getAttribute("maxLength")

        if (length == maxLength) {
            if (n !== '') {
                document.getElementById(n).focus();
            }
        }
        if (e.key == "Backspace") {
            document.getElementById(p).focus();
        }
    }

    let history = useNavigate();
    const resendValue = localStorage.getItem('resendValue');
    var sendEmail = localStorage.getItem('sendEmail');

    var a = sendEmail ? sendEmail.split("@") : "";
    var b = a[0];
    var newstr = "";
    for (var i in b) {
        if (i > 0 && i < b.length - 1) newstr += "*";
        else newstr += b[i];
    }

    const Ref = useRef(null);
    const dispatch = useDispatch({ });
    let errorsObj = { Otp: '' };
    const [error, setError] = useState(errorsObj);

    // Validation Add (Parth Roka)
    const PhoneNumber = (e) => {

        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

        if (!One) {
            errorObj.Otp = "Please enter OTP";
            error = true;
        }
        else if (!Two) {
            errorObj.Otp = "Please enter OTP";
            error = true;
        }
        else if (!Three) {
            errorObj.Otp = "Please enter OTP";
            error = true;
        }
        else if (!Four) {
            errorObj.Otp = "Please enter OTP";
            error = true;
        }
        else if (One && Two && Three && Four) {
            let combinee2 = One + Two + Three + Four;
            let otp_id = localStorage.getItem("otp_id")
            let admin_id = localStorage.getItem("admin_id")
            let formData = new FormData();

            formData.append('otp', combinee2);
            formData.append('otp_id', otp_id);
            formData.append('admin_id', admin_id);

            if (combinee2) {
                dispatch(dispatchLoadingStatus(true))
                dispatch(OtpAPI(formData))
            }
        }
        setError(errorObj);
    }

    const NumValid = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setOne(e.target.value)
        }
    }

    const NumValid2 = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setTwo(e.target.value)
        }
    }
    const NumValid3 = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setThree(e.target.value)
        }
    }
    const NumValid4 = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setFour(e.target.value)
        }
    }


    // Timer (Parth Roka)
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);

        return {
            total, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }

    const clearTimer = (e) => {
        setTimer('01:00');

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    }

    const onClickReset = (e) => {
        e.preventDefault();
        clearTimer(getDeadTime());
        setClick(Click + 1)
        localStorage.setItem("resendValue", Click);
        let formData = new FormData();

        formData.append('email', sendEmail);
        dispatch(dispatchLoadingStatus(true))
        dispatch(LoginAPI(formData));
    }

    useEffect(() => {

        clearTimer(getDeadTime());
        let otp_id = localStorage.getItem("otp_id");
        if (otp_id) {
            history('/Otp')
        }
        else {
            history('/')
        }

    }, [])

    return (

        <main className="main-content">
            <Loader />
            <div className="signUP-admin">
                <div className="container-fluid">
                    <div className="row justify-content-center align-content-center">
                        <div className="col-xl-4 col-lg-5 col-md-5 p-0">
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-7 col-sm-8">
                            <div className="signUp-admin-right signIn-admin-right   p-10">
                                <div className="row justify-content-center">
                                    <div className="col-xl-7 col-lg-8 col-md-12">
                                        <div className="edit-profile login-div mt-md-25 mt-0">
                                            <div className=" border-0 ">
                                                <div className=" border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                                                    <div className="edit-profile__title text-center">
                                                        <img src={logoWhite} className="logo" />
                                                    </div>
                                                </div>
                                                <form>
                                                    <div className="card-body">
                                                        <div className="">
                                                            <div className="form-group row mb-15">
                                                                <div className="col-sm-12 ">
                                                                    <h6 className="mb-2 text-center d-block text-white">Enter Verification code that we sent to  </h6>
                                                                    <h6 className='mb-4 text-center d-block text-white'> {newstr + "@" + a[1]}</h6>
                                                                    <label htmlFor="inputEmailIcon" className=" col-form-label color-dark fs-16 fw-500 align-center text-white">OTP Verification</label>
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <input type="text" onKeyUp={(event) => Move(event, 'tx1', 'tx1', 'tx2')} className="form-control fs-16 text-center ih-medium ip-gray radius-xs b-light" id="tx1" value={One} onChange={NumValid} maxLength="1" placeholder="-" />
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <input type="text" onKeyUp={(event) => Move(event, 'tx1', 'tx2', 'tx3')} className="form-control fs-16 text-center ih-medium ip-gray radius-xs b-light" id="tx2" value={Two} onChange={NumValid2} maxLength="1" placeholder="-" />
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <input type="text" onKeyUp={(event) => Move(event, 'tx2', 'tx3', 'tx4')} className="form-control fs-16 text-center ih-medium ip-gray radius-xs b-light" id="tx3" value={Three} onChange={NumValid3} maxLength="1" placeholder="-" />
                                                                </div>
                                                                <div className="col-lg-3 col-3">
                                                                    <input type="text" onKeyUp={(event) => Move(event, 'tx3', 'tx4', '')} className="form-control fs-16 text-center ih-medium ip-gray radius-xs b-light" id="tx4" value={Four} onChange={NumValid4} maxLength="1" placeholder="-" />
                                                                </div>
                                                            </div>
                                                            {error.Otp && <div className='newwCard error-msg'>{error.Otp} </div>}
                                                            <div className="button-group d-block justify-content-md-start justify-content-center m-auto text-center">
                                                                <button className="btn btn-primary btn-default m-auto btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn " onClick={PhoneNumber}>
                                                                    Submit
                                                                </button>
                                                            </div>
                                                            {resendValue == 2 ? "" :
                                                                counter === '00:00' ? <div className="mt-4 text-center">
                                                                    {
                                                                        resendValue >= 2 ? null : <div className="mt-4 text-center">

                                                                            <a href="" className="text-center d-block text-white py-3" onClick={onClickReset} cursor='not-allowed'> Resend OTP </a>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                    : <div className="mt-2 text-center">
                                                                        <p id='couter' style={{ color: 'white' }} >Resend OTP in {counter} </p>
                                                                    </div>
                                                            }
                                                            <ToastContainer />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    )
}


export default Otp;
