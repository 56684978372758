import React, { useEffect, useState, useRef } from "react";
import MainDashboard from "../Main Dashboard/MainDashboard";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import {
  dispatchLoadingStatus,
  listAllCategoryAPI,
  addCategoryAPI,
  editCategoryAPI,
  changeCategoryStatusAPI,
  deleteCategoryAPI,
} from "../../API/Action/globalAction";
import swal from "sweetalert";
import Loader from '../Loader/Loader';

// Added by Moinuddin Mansuri
function PollsList() {
  const [order, setOrder] = useState("ASC");


  // Sorting Function (Moinuddin Mansuri)
  const Sortt = (col) => {
    if (order === "ASC") {
      const sorted = [...allData22].sort((a, b) =>
        (a[col]?.toLowerCase() || "") > (b[col]?.toLowerCase() || "") ? 1 : -1
      );
      setAllData22(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...allData22].sort((a, b) =>
        (a[col]?.toLowerCase() || "") < (b[col]?.toLowerCase() || "") ? 1 : -1
      );
      setAllData22(sorted);
      setOrder("ASC");
    }
  };

  const MAX_FILE_SIZE = 100 * 1024;
  const [errorsdob, seterrorsdob] = useState(false);
  const [errorsprofile, seterrorsprofile] = useState(false);
  const [errorsprofile22, seterrorsprofile22] = useState(false);

  const [base64Code, setBase64Code] = useState("");
  const [b1, setb1] = useState("");


  const [fillErr, setFillErr] = useState(false);
  const [fillErr1, setFillErr1] = useState(false);
  const [fillErr2, setFillErr2] = useState(false);

  const [fillImage, setFillImage] = useState("");
  const [Fill, setbFill] = useState("");

  const [borderErr, setborderErr] = useState(false);
  const [borderErr1, setborderErr1] = useState(false);
  const [borderErr2, setborderErr2] = useState(false);

  const [borderImage, setborderImage] = useState("");
  const [border, setbborder] = useState("");



  const [squareErr, setSquareErr] = useState(false);
  const [squareErr1, setSquareErr1] = useState(false);
  const [squareErr2, setSquareErr2] = useState(false);

  const [squareImage, setSquareImage] = useState("");
  const [square, setSquare] = useState("");




  let accessDataByAdminIdReg = useSelector(
    (state) => state.globalReducer.accessDataByAdminIdReg
  );

  const [access, setAccess] = useState();

  useEffect(() => {
    if (accessDataByAdminIdReg) {
      if (accessDataByAdminIdReg.errorcode == 0) {
        setAccess(
          accessDataByAdminIdReg
            ? accessDataByAdminIdReg.data
            : accessDataByAdminIdReg.data
        );
        let alldata = accessDataByAdminIdReg.data;
        if (alldata.pollsManage == true) {
        } else {
          history("/Alltrip");
        }
      }
    }
  }, [accessDataByAdminIdReg]);

  let isPollsReadTrue = access ? access.pollsRead : "";
  let isPollsManageTrue = access ? access.pollsManage : "";
  let isPollsEditTrue = access ? access.pollsEdit : "";
  let isPollsCreateTrue = access ? access.pollsCreate : "";

  // Image Upload (Moinuddin Mansuri)
  const changeprofile =(event) => {
    if (event.target.files && event.target.files.length > 0) {
      // check if the files array exists and is not empty
      const fileName = event.target.files[0].name.toLowerCase();
      if (
        fileName.includes("jpg") ||
        fileName.includes("png") ||
        fileName.includes("jpeg") || fileName.includes("svg")
      ) {
        seterrorsdob(false);
        seterrorsprofile(false);
        seterrorsprofile22(false);

        if (event.target.files[0].size <= MAX_FILE_SIZE) {
          seterrorsdob(false);
          seterrorsprofile(false);
          seterrorsprofile22(false);
          setBase64Code(event.target.files[0]);
        } else {
          setBase64Code("");
          seterrorsprofile22(true);
          seterrorsdob(false);
          seterrorsprofile(false);
        }
      } else {
        seterrorsprofile(true);
        setBase64Code("");
        seterrorsprofile22(false);
      }
    }
  }

  // Image Upload (Moinuddin Mansuri)
  const changeFillImage =(event) => {
    if (event.target.files && event.target.files.length > 0) {
      const fileName = event.target.files[0].name.toLowerCase();
      if (
        fileName.includes("jpg") ||
        fileName.includes("png") ||
        fileName.includes("jpeg") || fileName.includes("svg")
      ) {
        setFillErr(false);
        setFillErr1(false);
        setFillErr2(false);

        if (event.target.files[0].size <= MAX_FILE_SIZE) {
          setFillErr(false);
          setFillErr1(false);
          setFillErr2(false);
          setFillImage(event.target.files[0]);
        } else {
          setFillImage("");
          setFillErr2(true);
          setFillErr(false);
          setFillErr1(false);
        }
      } else {
        setFillErr1(true);
        setFillImage("");
        setFillErr2(false);
      }
    }
  }


  // Image Upload (Moinuddin Mansuri)
  const changeBorderImage =(event) => {

    if (event.target.files && event.target.files.length > 0) {
      // check if the files array exists and is not empty
      const fileName = event.target.files[0].name.toLowerCase();
      if (
        fileName.includes("jpg") ||
        fileName.includes("png") ||
        fileName.includes("jpeg") || fileName.includes("svg")
      ) {
        setborderErr(false);
        setborderErr1(false);
        setborderErr2(false);

        if (event.target.files[0].size <= MAX_FILE_SIZE) {
          setborderErr(false);
          setborderErr1(false);
          setborderErr2(false);
          setborderImage(event.target.files[0]);
        } else {
          setborderImage("");
          setborderErr2(true);
          setborderErr(false);
          setborderErr1(false);
        }
      } else {
        setborderErr1(true);
        setborderImage("");
        setborderErr2(false);
      }
    }
  }


  // Image Upload (Moinuddin Mansuri)
  const squareBorderImage =(event) => {

    if (event.target.files && event.target.files.length > 0) {
      const fileName = event.target.files[0].name.toLowerCase();
      if (
        fileName.includes("jpg") ||
        fileName.includes("png") ||
        fileName.includes("jpeg") || fileName.includes("svg")
      ) {
        setSquareErr(false);
        setSquareErr1(false);
        setSquareErr2(false);

        if (event.target.files[0].size <= MAX_FILE_SIZE) {
          setSquareErr(false);
          setSquareErr1(false);
          setSquareErr2(false);
          setSquareImage(event.target.files[0]);
        } else {
          setSquareImage("");
          setSquareErr2(true);
          setSquareErr(false);
          setSquareErr1(false);
        }
      } else {
        setSquareErr1(true);
        setSquareImage("");
        setSquareErr2(false);
      }
    }
  }

  const [buttonValue, setButtonValue] = useState("");
  let history = useNavigate();
  const startdatePickerRef = useRef(null);
  const enddatePickerRef = useRef(null);
  let toggleReducer = useSelector((state) => state.globalReducer.toggle);

  let listAllCategoryReg = useSelector(
    (state) => state.globalReducer.listAllCategoryReg
  );
  let addCategoryReg = useSelector(
    (state) => state.globalReducer.addCategoryReg
  );
  let editCategoryReg = useSelector(
    (state) => state.globalReducer.editCategoryReg
  );
  let changeCategoryStatusReg = useSelector(
    (state) => state.globalReducer.changeCategoryStatusReg
  );

  let deleteCategoryReg = useSelector(
    (state) => state.globalReducer.deleteCategoryReg
  );


  const [pollsToggle, setPollsToggle] = useState(false);
  const [allData22, setAllData22] = useState();
  const [categoryDetails, setCategoryDetails] = useState();

  const dispatch = useDispatch({});

  const [page, setpage] = useState(1);
  const [indexCh, setIndexCh] = useState(0);


  // Api Call (Moinuddin Mansuri)
  useEffect(() => {
    let token = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("token", token);
    formData.append("start", 0);
    formData.append("limit", 10);
    dispatch(dispatchLoadingStatus(true));
    dispatch(listAllCategoryAPI(formData));
  }, []);

  useEffect(() => {
    if (listAllCategoryReg) {
      if (listAllCategoryReg.errorcode == 0) {
        setAllData22(listAllCategoryReg ? listAllCategoryReg.data : []);
      } else {
        setAllData22([]);
      }
    }
  }, [listAllCategoryReg]);

  useEffect(() => {
    if (addCategoryReg) {
      if (addCategoryReg.errorcode == 0) {
        MainSearch(0);
        setCategory("");
        setPollsToggle(false);
      } else {
        setPollsToggle(true);
      }
    }

    if (addCategoryReg) {
      MainSearch((page - 1) * 10, page);
    }
  }, [addCategoryReg]);

  useEffect(() => {
    if (editCategoryReg) {
      if (editCategoryReg.errorcode == 0) {
        MainSearch(0);
        setCategory("");
        setPollsToggle(false);
      } else {
        setPollsToggle(true);
      }
    }

    if (editCategoryReg) {
      MainSearch((page - 1) * 10, page);
    }
  }, [editCategoryReg]);

  useEffect(() => {
  }, [addCategoryReg]);

  useEffect(() => {
  }, [editCategoryReg]);



  useEffect(() => {
    if (deleteCategoryReg) {
      MainSearch((page - 1) * 10, page);
    }
  }, [deleteCategoryReg]);


  // Search Main Function (Moinuddin Mansuri)
  function MainSearch(key) {
    let token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("token", token);
    formData.append("name", frname ? frname : "");
    formData.append("start", key);
    formData.append("limit", 10);

    dispatch(listAllCategoryAPI(formData));
  }

  const [category, setCategory] = useState(
    categoryDetails ? categoryDetails.name : ""
  );
  const [categoryError, setCategoryError] = useState(false);
  const [categoryError22, setCategoryError22] = useState(false);
  const [categoryError33, setCategoryError33] = useState(false);

  function handleCatagory(e) {
    const result = /^[A-Za-z]( ?[A-Za-z])*$/;
    // const result =/^[A-Za-z]( ?[A-Za-z])*$/;
    if (e.target.value.trim()) {
      setCategory(e.target.value);
    }
  }



  // Delete Function (Moinuddin Mansuri)
  function deleteRole(i) {
    swal({
      // title: "Are you sure you want inactive this center?",
      text: "Are you sure you want to delete sub admin?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let formData = new FormData();
        formData.append("token", localStorage.getItem("token"));
        formData.append("categoryId", i._id);

        dispatch(dispatchLoadingStatus(true));
        dispatch(deleteCategoryAPI(formData));
      } else {
        // window.location.href = window.location.origin + '/FreelancerRoleManagement';
      }
    });
  }

  // Api Call for Image Upload (Moinuddin Mansuri)
  function allDetails(e) {
    e.preventDefault();

    if (!base64Code) {
      seterrorsdob(true);
    }

    if (!fillImage) {
      setFillErr(true);
    }

    if (!borderImage) {
      setborderErr(true);
    }

    if (!squareImage) {
      setSquareErr(true);
    }

    let nameRe = /^[A-Za-z]( ?[A-Za-z\/-])*$/;

    // let nameRe = /^[a-zA-Z\s-/]$/g

    if (category) {
      if (category.length >= 3) {
        setCategoryError22(false);
        setCategoryError(false);
        setCategoryError33(false);

        if (!nameRe.test(category)) {
          setCategoryError22(true);
          setCategoryError(false);
          setCategoryError33(false);
        } else {
          setCategoryError22(false);
          setCategoryError(false);
          setCategoryError33(false);
        }
      } else {
        setCategoryError22(false);
        setCategoryError(false);
        setCategoryError33(true);
      }
    } else {
      setCategoryError(true);
      setCategoryError22(false);
      setCategoryError33(false);
    }

    if (buttonValue == "editPolls") {
      if (
        category.length >= 3 &&
        nameRe.test(category) &&
        category &&
        base64Code &&
        fillImage &&
        borderImage &&
       squareImage
      ) {
        let token = localStorage.getItem("token");
        var formData = new FormData();
        formData.append("token", token);
        formData.append("categoryId", categoryDetails._id);

        formData.append("name", category);
        formData.append("categoryImg", base64Code);
        formData.append("categoryImgBorder", borderImage);
        formData.append("categoryImgFill", fillImage);
        formData.append("categoryImgsquare", squareImage);




        dispatch(editCategoryAPI(formData));
        dispatch(dispatchLoadingStatus(true));
      }
    } else {
      if (
        category.length >= 3 &&
        nameRe.test(category) &&
        category &&
        base64Code &&
        fillImage &&
        borderImage &&
        squareImage
      ) {
        let token = localStorage.getItem("token");
        var formData = new FormData();
        formData.append("token", token);
        formData.append("name", category);
        formData.append("categoryImg", base64Code);
        formData.append("categoryImgBorder", borderImage);
        formData.append("categoryImgFill", fillImage);
        formData.append("categoryImgsquare", squareImage);


        dispatch(addCategoryAPI(formData));
        dispatch(dispatchLoadingStatus(true));
      }
    }
  }

  useEffect(() => {
    if (changeCategoryStatusReg) {
      MainSearch((page - 1) * 10, page);
    }
  }, [changeCategoryStatusReg]);

  const [frname, setSetFrname] = useState("");

  useEffect(() => {
    if (frname) {
      MainSearch(0);
      return;
    }

    if (frname <= 0 || frname == "") {
      MainSearch(0);
      return;
    }
  }, [frname]);

  function handleCheck(e, i) {
    if (e.target.checked == true) {
      swal({
        text: "Are you sure you want to active category?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((Responce) => {
        if (Responce) {
          let formData = new FormData();
          formData.append("token", localStorage.getItem("token"));
          formData.append("categoryId", i._id);
          dispatch(dispatchLoadingStatus(true));
          dispatch(changeCategoryStatusAPI(formData));

          swal({
            text: "Category is active!",
            icon: "success",
          });
        } else {
        }
      });
    } else {
      swal({
        text: "Are you sure you want to inactive category?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((Responce) => {
        if (Responce) {
          let formData = new FormData();
          formData.append("token", localStorage.getItem("token"));
          formData.append("categoryId", i._id);
          dispatch(dispatchLoadingStatus(true));
          dispatch(changeCategoryStatusAPI(formData));

          swal({
            text: "Category is inactive!",
            icon: "success",
          });
        } else {
        }
      });
    }
  }

  return (
    <>
      <Loader />
      <div
        className={toggleReducer == false ? "contents expanded" : "contents"}
      >
        <div className="crm poll-page-main">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-main mt-5">
                  <h4 className="text-capitalize breadcrumb-title ">
                    Polls List
                  </h4>

                  <div class="breadcrumb-action justify-content-end flex-wrap">
                    <div className="">
                      {" "}
                      <ul class="atbd-breadcrumb nav">
                        <li class="atbd-breadcrumb__item"></li>
                        <li class="atbd-breadcrumb__item">
                          <a href="/Alltrip">Trips</a>
                          <span className="breadcrumb__seperator">
                            <span className="la la-slash"></span>
                          </span>
                        </li>
                        <li className="atbd-breadcrumb__item">
                          <a href="/Pollslist">Polls</a>
                          <span className="breadcrumb__seperator"></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="action-btn">
                  <div className="with-icon">
                    <span className="fa fa-search"></span>
                    <input
                      className="form-control mr-sm-2 box-shadow-none"
                      type="search"
                      maxLength={30}
                      placeholder="Search..."
                      aria-label="Search"
                      onChange={(e) =>
                        setSetFrname(
                          e.target.value.trim() == "" ? "" : e.target.value
                        )
                      }
                      value={frname}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 offset-lg-4">
                <div className="breadcrumb-action justify-content-end flex-wrap mb-3">
                  <div className="action-btn">
                    <button
                      className="btn btn-sm btn-primary btn-add mb-4"
                      onClick={() => {
                        if (isPollsCreateTrue == true) {
                          setPollsToggle(true);
                          setButtonValue("");


                          setBase64Code("");
                          setFillImage("");
                          setborderImage("");
                          setSquareImage("");

                          setb1("");
                          setbFill("");
                          setbborder("");
                          setSquare("")
                          
                          setCategory("");
                          setCategoryError(false);
                          setCategoryError22(false);
                          setCategoryError33(false);
                        } else {
                          swal({
                            text: "you don't have permission",
                            icon: "warning",
                            dangerMode: true,
                          });
                        }
                      }}
                    >
                      <i className="la la-plus"></i> Add Category
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {pollsToggle ? (
              <div className="col-lg-12">
                <div className="card card-horizontal card-default card-md mb-4">
                  <div className="card-header">
                    <div className="card-body py-md-30">
                      <div className="horizontal-form">
                        <form className="row">
                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label
                                htmlFor="inputName"
                                className=" col-form-label color-dark fs-14 fw-500 align-center"
                              >
                                Poll Category Name *
                              </label>

                              <input
                                type="text"
                                className="form-control ih-medium ip-light radius-xs b-light px-15"
                                autoComplete="off"
                                placeholder="Enter Poll Category"
                                value={category}
                                onChange={(e) => handleCatagory(e)}
                                maxLength={30}
                              />
                              {categoryError && (
                                <span style={{ color: "#BE312E" }}>
                                  Please enter poll category name
                                </span>
                              )}
                              {categoryError22 == true
                                ? categoryError22 && (
                                    <span style={{ color: "#BE312E" }}>
                                      Please enter valid name
                                    </span>
                                  )
                                : categoryError33 && (
                                    <span style={{ color: "#BE312E" }}>
                                      Please enter minimum 3 character name
                                    </span>
                                  )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label
                                htmlFor="inputName"
                                className=" col-form-label color-dark fs-14 fw-500 align-center"
                              >
                                Select Image *
                              </label>

                              <label
                                htmlFor="profile"
                                class="btn btn-outline-primary form-control waves-effect waves-light upload-btn"
                              >
                                {base64Code || b1
                                  ? base64Code.name || b1.split("_").at(-1)
                                  : "Upload"}
                              </label>
                              <input
                                type="file"
                                accept=".png, .jpg"
                                class="btn btn-outline-primary form-control waves-effect waves-light"
                                name="file"
                                onChange={(event) => changeprofile(event)}
                                id="profile"
                                style={{ display: "none" }}
                              />

                              {errorsprofile == true
                                ? errorsprofile && (
                                    <span style={{ color: "#BE312E" }}>
                                      The photo must be a file of type: JPG,
                                      PNG, JPEG , SVG
                                    </span>
                                  )
                                : errorsdob == true
                                ? errorsdob && (
                                    <span style={{ color: "#BE312E" }}>
                                      Please select image
                                    </span>
                                  )
                                : errorsprofile22 && (
                                    <span style={{ color: "#BE312E" }}>
                                      File size should not exceed 100KB
                                    </span>
                                  )}
                            </div>
                          </div>




                         
                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label
                                htmlFor="inputNameee"
                                className=" col-form-label color-dark fs-14 fw-500 align-center"
                              >
                                Select Border Image *
                              </label>

                              <label
                                htmlFor="border"
                                class="btn btn-outline-primary form-control waves-effect waves-light upload-btn"
                              >
                                {borderImage || border
                                  ? borderImage.name || border.split("_").at(-1)
                                  : "Upload"}
                              </label>
                              <input
                                type="file"
                                accept=".png, .jpg"
                                class="btn btn-outline-primary form-control waves-effect waves-light"
                                name="file"
                                onChange={(event) => changeBorderImage(event)}
                                id="border"
                                style={{ display: "none" }}
                              />

                              {borderErr1 == true
                                ? borderErr1 && (
                                    <span style={{ color: "#BE312E" }}>
                                      The photo must be a file of type: JPG,
                                      PNG, JPEG , SVG
                                    </span>
                                  )
                                : borderErr == true
                                ? borderErr && (
                                    <span style={{ color: "#BE312E" }}>
                                      Please select image
                                    </span>
                                  )
                                : borderErr2 && (
                                    <span style={{ color: "#BE312E" }}>
                                      File size should not exceed 100KB
                                    </span>
                                  )}
                            </div>
                          </div>




                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label
                                htmlFor="inputNameee"
                                className=" col-form-label color-dark fs-14 fw-500 align-center"
                              >
                                Select Fill Image *
                              </label>

                              <label
                                htmlFor="fill"
                                class="btn btn-outline-primary form-control waves-effect waves-light upload-btn"
                              >
                                {fillImage || Fill
                                  ? fillImage.name || Fill.split("_").at(-1)
                                  : "Upload"}
                              </label>
                              <input
                                type="file"
                                accept=".png, .jpg"
                                class="btn btn-outline-primary form-control waves-effect waves-light"
                                name="file"
                                onChange={(event) => changeFillImage(event)}
                                id="fill"
                                style={{ display: "none" }}
                              />

                              {fillErr1 == true
                                ? fillErr1 && (
                                    <span style={{ color: "#BE312E" }}>
                                      The photo must be a file of type: JPG,
                                      PNG, JPEG , SVG
                                    </span>
                                  )
                                : fillErr == true
                                ? fillErr && (
                                    <span style={{ color: "#BE312E" }}>
                                      Please select image
                                    </span>
                                  )
                                : fillErr2 && (
                                    <span style={{ color: "#BE312E" }}>
                                      File size should not exceed 100KB
                                    </span>
                                  )}
                            </div>
                          </div>



                          <div className="col-lg-6">
                            <div className="form-group ">
                              <label
                                htmlFor="inputNameee"
                                className=" col-form-label color-dark fs-14 fw-500 align-center"
                              >
                                Select Square Image *
                              </label>

                              <label
                                htmlFor="square"
                                class="btn btn-outline-primary form-control waves-effect waves-light upload-btn"
                              >
                                {squareImage || square
                                  ? squareImage.name || square.split("_").at(-1)
                                  : "Upload"}
                              </label>
                              <input
                                type="file"
                                accept=".png, .jpg"
                                class="btn btn-outline-primary form-control waves-effect waves-light"
                                name="file"
                                onChange={(event) => squareBorderImage(event)}
                                id="square"
                                style={{ display: "none" }}
                              />

                              {squareErr1 == true
                                ? squareErr1 && (
                                    <span style={{ color: "#BE312E" }}>
                                      The photo must be a file of type: JPG,
                                      PNG, JPEG , SVG
                                    </span>
                                  )
                                : squareErr == true
                                ? squareErr && (
                                    <span style={{ color: "#BE312E" }}>
                                      Please select image
                                    </span>
                                  )
                                : squareErr2 && (
                                    <span style={{ color: "#BE312E" }}>
                                      File size should not exceed 100KB
                                    </span>
                                  )}
                            </div>
                          </div>


                          <div className="col-lg-12">
                            <div className="centerd-btn m-20-auto d-block mt-35 text-center">
                              <button
                                type="button"
                                className="btn d-inline btn-default btn-squared border-normal bg-normal px-20 "
                                onClick={() => {
                                  setFillImage("")
                                  setborderImage("")
                                  setBase64Code("");
                                  setSquareImage("")


                                  seterrorsprofile(false);
                                  seterrorsprofile22(false);
                                  seterrorsdob(false);


                                  setFillErr(false);
                                  setFillErr1(false);
                                  setFillErr2(false);


                                  setSquareErr(false);
                                  setSquareErr1(false);
                                  setSquareErr2(false);


                                  setborderErr(false);
                                  setborderErr1(false);
                                  setborderErr2(false);


                                  setPollsToggle(false);
                                  setCategory("");
                                  setCategoryError(false);
                                  setCategoryError22(false);
                                  setCategoryError33(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn d-inline btn-primary btn-default btn-squared px-30 mx-10 save-btn"
                                onClick={allDetails}
                              >
                                {" "}
                                {buttonValue == "editPolls"
                                  ? "Edit"
                                  : "Add"}{" "}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row ">
              <div className="col-lg-12">
                <div className="card card-default card-md mb-4">
                  <div className="card-body">
                    <div className="table4 bg-white mb-30 ">
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <thead>
                            <tr className="userDatatable-header">
                              <th>
                                <span className="userDatatable-title">
                                  Sr No.
                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Category
                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Category border
                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Category fill
                                </span>
                              </th>

                              <th>
                                <span className="userDatatable-title">
                                  Category Square
                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Category Name
                                  <i
                                    onClick={() => Sortt("name")}
                                    className="fa fa-sort"
                                    height="15px"
                                    width="15px"
                                    style={{ marginLeft: 5 }}
                                  ></i>
                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Created
                                <i onClick={() => Sortt("createdDate")} className="fa fa-sort" height="15px" width="15px" style={{ marginLeft: 5 }}></i>

                                </span>
                              </th>

                              <th>
                                <span className="userDatatable-title">
                                  Active/Inactive
                                </span>
                              </th>
                              <th>
                                <span className="userDatatable-title">
                                  Action
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {allData22
                              ? allData22.length > 0
                                ? allData22.map((i, index) => (
                                    <tr>
                                      <td> {page * 10 + index + 1 - 10}.</td>
                                      <td>
                                        <div>
                                          <img
                                            src={i.categoryImg}
                                            alt="Example Image"
                                          />
                                        </div>
                                      </td>

                                      <td>
                                        <div>
                                          <img
                                          src={i.categoryImgBorder}
                                            alt="Example Image border"
                                          />
                                        </div>
                                      </td>

                                      <td>
                                        <div>
                                          <img
                                            src={i.categoryImgFill}
                                            alt="Example Image fill"
                                          />
                                        </div>
                                      </td>

                                      <td>
                                        <div>
                                          <img
                                          src={i.categoryImgsquare}
                                            alt="Example Image Square"
                                          />
                                        </div>
                                      </td>

                                      <td>
                                        {/* className="userDatatable-content" */}
                                        <div>{i.name ? i.name : ""}</div>
                                      </td>
                                      <td>
                                        <div>{i.createdDate}</div>
                                      </td>

                                      <td>
                                        <div className="atbd-switch-wrap">
                                          <label className="switch">
                                            <input
                                              type="checkbox"
                                              id="toggleSwitch"
                                              checked={i.isActive}
                                              onChange={(e) => {
                                                if (isPollsEditTrue == true) {
                                                  handleCheck(e, i);
                                                } else {
                                                  swal({
                                                    text: "you don't have permission",
                                                    icon: "warning",
                                                    dangerMode: true,
                                                  });
                                                }
                                              }}
                                            />
                                            <span className="slider round"></span>
                                          </label>
                                        </div>
                                      </td>

                                      <td className="d-flex">
                                        <i
                                          className="cursor-pointer far fa-edit mr-3 font-size-18"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            if (isPollsEditTrue == true) {
                                              setBase64Code(i.categoryImg);
                                              setFillImage(i.categoryImgFill);
                                              setborderImage(i.categoryImgBorder);
                                              setSquareImage(i.categoryImgsquare);


                                              setb1(i.categoryImg);
                                              setbborder(i.categoryImgBorder);
                                              setbFill(i.categoryImgFill);
                                              setSquare(i.categoryImgsquare);


                                              setFillErr(false);
                                              setFillErr1(false);
                                              setFillErr2(false);

                                              setborderErr(false);
                                              setborderErr1(false);
                                              setborderErr2(false);

                                              setSquareErr(false);
                                              setSquareErr1(false);
                                              setSquareErr2(false);


                                              seterrorsprofile(false);
                                              seterrorsprofile22(false);
                                              seterrorsdob(false);


                                              setPollsToggle(true);
                                              setButtonValue("editPolls");
                                              setCategory(i.name);
                                              setCategoryDetails(i);
                                              setCategoryError(false);
                                              setCategoryError22(false);
                                              setCategoryError33(false);
                                            } else {
                                              swal({
                                                text: "you don't have permission",
                                                icon: "warning",
                                                dangerMode: true,
                                              });
                                            }
                                          }}
                                        >
                                          {" "}
                                        </i>

                                        <i
                                        className="fa fa-trash"
                                        style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            if (isPollsEditTrue == true) {
                                              deleteRole(i);
                                            } else {
                                              swal({
                                                text: "you don't have permission",
                                                icon: "warning",
                                                dangerMode: true,
                                              });
                                            }
                                          }}
                                        ></i>
                                      </td>
                                    </tr>
                                  ))
                                : ""
                              : ""}
                          </tbody>
                        </table>
                      </div>

                      {allData22 ? (
                        allData22.length > 0 ? null : (
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: "500",
                              color: "#000",
                              marginTop: "25px",
                            }}
                          >
                            <p>No data found</p>{" "}
                          </div>
                        )
                      ) : null}

                      <div className=" btns-table align-items-center justify-content-end">
                        {listAllCategoryReg ? (
                          listAllCategoryReg.count > 0 ? (
                            <div className="row align-items-center wrapper-pg-main pagination">
                              <div className="col-6"></div>
                              <div className="col-6">
                                <div className="wrapper-pgn">
                                  <div className="pgn pgn-default">
                                    <div
                                      className="prev "
                                      onClick={() =>
                                        document
                                          .getElementById("content")
                                          .scrollBy(-50, 0)
                                      }
                                    >
                                      <i
                                        className="fa fa-chevron-left"
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </div>
                                    <div className="pages" id="content">
                                      {(() => {
                                        const pages = [];

                                        for (
                                          let i = 1;
                                          i < listAllCategoryReg.count / 10 + 1;
                                          i++
                                        ) {
                                          // for (let i = 1; i <= 20; i++) {
                                          pages.push(
                                            <a
                                              className={
                                                page == i ? "active" : ""
                                              }
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                MainSearch((i - 1) * 10);
                                                setpage(i);
                                                setIndexCh(indexCh + 1);
                                              }}
                                            >
                                              {i}
                                            </a>
                                          );
                                        }

                                        return pages;
                                      })()}
                                    </div>
                                    <div
                                      onClick={() => {
                                        document
                                          .getElementById("content")
                                          .scrollBy(50, 0);
                                      }}
                                      className=" next "
                                    >
                                      <i
                                        className="fa fa-chevron-right"
                                        style={{ cursor: "pointer" }}
                                      ></i>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <MainDashboard />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PollsList;
