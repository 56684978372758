import React, { useEffect, useState } from 'react';
import MainDashboard from "../Main Dashboard/MainDashboard";
import { useDispatch, useSelector } from 'react-redux';
import Footer from "../Footer/Footer";
import { dispatchLoadingStatus, addSubAdminAPI, editSubAdminAPI } from '../../API/Action/globalAction';
import Loader from '../Loader/Loader';
import { useNavigate } from "react-router-dom";

// Added by Parth Roka
function AddAdmin(props) {

    let accessDataByAdminIdReg = useSelector(
        (state) => state.globalReducer.accessDataByAdminIdReg
    );

    let props22 = props ? props.state : "";

    useEffect(() => {
        if (accessDataByAdminIdReg) {
            if (accessDataByAdminIdReg.errorcode == 0) {

                if (props22) {
                    let alldata = accessDataByAdminIdReg.data
                    if (alldata.adminUserManagementEdit == true) {

                    } else {
                        history("/Alltrip")
                    }
                } else {
                    let alldata = accessDataByAdminIdReg.data
                    if (alldata.adminUserManagementCreate == true) {

                    } else {
                        history("/Alltrip")
                    }
                }
            }
        }
    }, [accessDataByAdminIdReg]);

    let toggleReducer = useSelector((state) => state.globalReducer.toggle);

    const [email, setEmail] = useState(props22 ? props22.email : "");
    const [emailError, setEmailError] = useState(false)

    const [Name, setName] = useState(props22 ? props22.username : "");
    const [NameError, setNameError] = useState("");

    const [Mobile, setMobile] = useState(props22 ? props22.phone : "");
    const [MobileError, setMobileError] = useState(false)
    const [MobileError22, setMobileError22] = useState(false)

    const [Password, setPassword] = useState("");
    const [PasswordError, setPasswordError] = useState(false);
    const [ConformPassword, setConformPassword] = useState("");
    const [ConformPasswordError, setConformPasswordError] = useState(false);
    const [BothPasswordError, setBothPasswordError] = useState(false);
    const [eyes, setEyes] = useState(false);
    const [eyes22, setEyes22] = useState(false);
    const [PasswordError22, setPasswordError22] = useState(false);
    const [ConformPasswordError22, setConformPasswordError22] = useState(false);

    const [preferRoleError, setPreferRoleError] = useState(false);


    let history = useNavigate();


    useEffect(() => {
        dispatch(dispatchLoadingStatus(false))

    }, [])

    const dispatch = useDispatch({});



    const handleEmail = (e) => {
        setEmail(e)
        setEmailError("");
    }


    function isValidEmail(email) {
        return /^([\w-\.]+@([\w-]+\.)+[\w-]{2,3})?$/.test(email);
    }

    function handleName(e) {

        const result = /^[A-Za-z]( ?[A-Za-z] ?)*$/;
        // const result =/^[A-Za-z]( ?[A-Za-z])*$/;
        if (e.target.value === "" || result.test(e.target.value)) {
            setName(e.target.value);
        }
    }

    const handleMobile = (e) => {

        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setMobile(e.target.value)
        }

    }

    // Main function (Parth Roka)
    const allDeatils = () => {

        if (email) {
            if (!isValidEmail(email)) {
                setEmailError(<span style={{ "color": "#BE312E" }}>Please enter valid email</span>);
            }
        }
        else {
            setEmailError(<span style={{ "color": "#BE312E" }}>Please enter email</span>);
        }
        let nameRe = /^[A-Za-z]( ?[A-Za-z])*$/;

        if (Name) {
            if (Name.length >= 3) {
                if (nameRe.test(Name)) {
                    setNameError("")
                }
                else {
                    setNameError(" Please enter valid name")
                }
            }
            else {
                setNameError("Please enter minimum 3 character name")
            }
        }
        else {
            setNameError("Please enter name")
        }

        let MobileRe = /^[0-9\b]+$/;
        const regx4 = /^[0]?[123456789]\d{9}$/
        let regx2 = /^($|[^0])/;

        
        if (Mobile) {

            if (!MobileRe.test(Mobile) || !regx4.test(Mobile) || !regx2.test(Mobile) || Mobile.length !== 10) {
                setMobileError22(true)
                setMobileError(false)
            }
            else {
                setMobileError22(false)
                setMobileError(false)
            }
        }
        else {
            setMobileError(true)
            setMobileError22(false)
        }

        if (props22) {
            if (Password || ConformPassword) {
                if (Password) {
                    if (Password.length >= 8 && Password.length <= 16) {
                        setPasswordError22(false);
                        setPasswordError(false)
                    }
                    else {
                        setPasswordError22(true);
                        setPasswordError(false)
                    }

                }
                else {
                    setPasswordError(true)
                    setPasswordError22(false);
                }
                if (ConformPassword) {
                    if (ConformPassword.length >= 8 && ConformPassword.length <= 16) {
                        setConformPasswordError22(false);
                        setConformPasswordError(false)
                    }
                    else {
                        setConformPasswordError22(true);
                        setConformPasswordError(false)
                    }
                }
                else {
                    setConformPasswordError(true)
                    setConformPasswordError22(false);
                }
            }
            else {
                setPasswordError22(false);
                setPasswordError(false);
                setConformPasswordError22(false);
                setConformPasswordError(false);
            }
        }
        else {
            if (Password) {
                if (Password.length >= 8 && Password.length <= 16) {
                    setPasswordError22(false);
                    setPasswordError(false)
                }
                else {
                    setPasswordError22(true);
                    setPasswordError(false)
                }
            }
            else {
                setPasswordError(true)
                setPasswordError22(false);
            }
            if (ConformPassword) {
                if (ConformPassword.length >= 8 && ConformPassword.length <= 16) {
                    setConformPasswordError22(false);
                    setConformPasswordError(false)
                }
                else {
                    setConformPasswordError22(true);
                    setConformPasswordError(false)
                }
            }
            else {
                setConformPasswordError(true)
                setConformPasswordError22(false);
            }
        }

        let trueKeys = Object.keys(tripsChecked).filter(key => tripsChecked[key]);
        const trueKeys22 = Object.keys(userListChecked).filter(key => userListChecked[key]);
        const trueKeys33 = Object.keys(adminUserManagementChecked).filter(key => adminUserManagementChecked[key]);
        const trueKeys44 = Object.keys(pollsChecked).filter(key => pollsChecked[key]);
        const trueKeys55 = Object.keys(inquiryChecked).filter(key => inquiryChecked[key]);

        if (trueKeys.length > 0 || trueKeys22.length > 0 || trueKeys33.length > 0 || trueKeys44.length > 0 || trueKeys55.length > 0) {
            setPreferRoleError(false)
        }
        else {
            setPreferRoleError(true)
        }

        if (props22) {
            if (nameRe.test(Name) &&
                Name &&
                Name.length >= 3 && Mobile && Mobile.length == 10 && email && (isValidEmail(email))) {

                if (trueKeys.length > 0 || trueKeys22.length > 0 || trueKeys33.length > 0 || trueKeys44.length > 0 || trueKeys55.length > 0) {
                    let token = localStorage.getItem('token')
                    let formData = new FormData();
                    formData.append("token", token);
                    formData.append("subAdminId", props22 ? props22._id : "");
                    formData.append("username", Name);
                    formData.append("phone", Mobile);
                    formData.append("accessData", JSON.stringify(accessdata));

                    dispatch(dispatchLoadingStatus(true))
                    dispatch(editSubAdminAPI(formData))
                }
            }
        }
        else {
            if (nameRe.test(Name) &&
                Name &&
                Name.length >= 3 && Mobile && Mobile.length == 10 && email && (isValidEmail(email))) {
                if (trueKeys.length > 0 || trueKeys22.length > 0 || trueKeys33.length > 0 || trueKeys44.length > 0 || trueKeys55.length > 0) {

                    let token = localStorage.getItem('token')
                    let formData = new FormData();

                    // var accessData = {
                    //     "trip-manage": trueKeys,
                    //     "userlist-manage": trueKeys22,
                    //     "admin-manage": trueKeys33
                    // };


                    // for (let key in accessData) {
                    //   lowerCaseData[key.toLowerCase()] = accessData[key].map(item => item.toLowerCase());
                    // }

                    formData.append("token", token);
                    formData.append("email", email);
                    formData.append("username", Name);
                    formData.append("phone", Mobile);
                    formData.append("accessData", JSON.stringify(accessdata));

                    var entries = formData.entries();
                    dispatch(dispatchLoadingStatus(true))
                    dispatch(addSubAdminAPI(formData))
                }

            }
        }


    }

    let initialState = {
        Read: props22 ? props22.accessData.adminUserManagementRead : false,
        Manage: props22 ? props22.accessData.adminUserManagementManage : false,
        Create: props22 ? props22.accessData.adminUserManagementCreate : false,
        Edit: props22 ? props22.accessData.adminUserManagementEdit : false,
        Delete: props22 ? props22.accessData.adminUserManagementDelete : false,


    };

    const initialState22 = {
        Read: props22 ? props22.accessData.tripRead : false,
        Manage: props22 ? props22.accessData.tripManage : false,
        Delete: props22 ? props22.accessData.tripDelete : false


    };

    const initialState33 = {
        Read: props22 ? props22.accessData.userRead : false,
        Manage: props22 ? props22.accessData.userManage : false,
        Delete: props22 ? props22.accessData.userDelete : false,
        Edit: props22 ? props22.accessData.userEdit : false,


    };


    const initialState44 = {
        Read: props22 ? props22.accessData.pollsRead : false,
        Manage: props22 ? props22.accessData.pollsManage : false,
        Create: props22 ? props22.accessData.pollsCreate : false,
        Edit: props22 ? props22.accessData.pollsEdit : false,


    };

    const initialState55 = {
        Read: props22 ? props22.accessData.inquiryRead : false,
        Manage: props22 ? props22.accessData.inquiryManage : false,
    };

    const [dashboardChecked, setDashboardChecked] = useState(initialState);
    const [adminUserManagementChecked, setAdminUserManagementChecked] = useState(initialState);
    const [tripsChecked, setTripsChecked] = useState(initialState22);
    const [userListChecked, setUserListChecked] = useState(initialState33);
    const [pollsChecked, setPollsChecked] = useState(initialState44);
    const [inquiryChecked, setInquiryChecked] = useState(initialState55);

    const [accessdata, setAccessdata] = useState({
        tripRead: tripsChecked.Read,
        tripManage: tripsChecked.Manage,
        tripDelete: tripsChecked.Delete,

        userRead: userListChecked.Read,
        userManage: userListChecked.Manage,
        userDelete: userListChecked.Delete,
        userEdit: userListChecked.Edit,

        adminUserManagementRead: adminUserManagementChecked.Read,
        adminUserManagementManage: adminUserManagementChecked.Manage,
        adminUserManagementDelete: adminUserManagementChecked.Delete,
        adminUserManagementCreate: adminUserManagementChecked.Create,
        adminUserManagementEdit: adminUserManagementChecked.Edit,

        pollsRead: pollsChecked.Read,
        pollsManage: pollsChecked.Manage,
        pollsCreate: pollsChecked.Create,
        pollsEdit: pollsChecked.Edit,

        inquiryRead: inquiryChecked.Read,
        inquiryManage: inquiryChecked.Manage,

    })


  // for select the all checkbox (Parth Roka)
    const handleAllChecked = (event, type) => {
        const isChecked = event.target.checked;
        let list = accessdata;

        if (type === "trips") {

            list['tripRead'] = isChecked;
            list['tripManage'] = isChecked;
            list['tripDelete'] = isChecked;

            setTripsChecked({
                Read: isChecked,
                Manage: isChecked,
                Delete: isChecked
            });
        }

        else if (type === "userlist") {
            list['userRead'] = isChecked;
            list['userManage'] = isChecked;
            list['userDelete'] = isChecked;
            list['userEdit'] = isChecked;

            setUserListChecked({
                Read: isChecked,
                Manage: isChecked,
                Delete: isChecked,
                Edit: isChecked,
            });
        }

        else if (type === "polls") {

            list['pollsRead'] = isChecked;
            list['pollsManage'] = isChecked;
            list['pollsCreate'] = isChecked;
            list['pollsEdit'] = isChecked;

            setPollsChecked({
                Read: isChecked,
                Manage: isChecked,
                Create: isChecked,
                Edit: isChecked,
            });
        }

        else if (type === "adminusermanagement") {

            list['adminUserManagementRead'] = isChecked
            list['adminUserManagementManage'] = isChecked
            list['adminUserManagementCreate'] = isChecked
            list['adminUserManagementEdit'] = isChecked
            list['adminUserManagementDelete'] = isChecked

            setAdminUserManagementChecked({
                Read: isChecked,
                Manage: isChecked,
                Create: isChecked,
                Edit: isChecked,
                Delete: isChecked
            });
        }

        else if (type === "inquiry") {
            list['inquiryRead'] = isChecked;
            list['inquiryManage'] = isChecked;

            setInquiryChecked({
                Read: isChecked,
                Manage: isChecked,
            });
        }
        setAccessdata(list)
    };

    // for select checkbox (Parth Roka)
    const handleChecked = (event, type) => {
        const name = event.target.name;
        const isChecked = event.target.checked;
        let list = accessdata;
        if (type === "trips") {
            if (name == "Read") {
                list['tripRead'] = isChecked
            }
            if (name == "Manage") {
                list['tripManage'] = isChecked
            }
            if (name == "Delete") {
                list['tripDelete'] = isChecked
            }
            setTripsChecked({
                ...tripsChecked,
                [name]: isChecked,

            });
        }

        else if (type === "userlist") {
            if (name == "Read") {
                list['userRead'] = isChecked
            }
            if (name == "Manage") {
                list['userManage'] = isChecked
            }
            if (name == "Delete") {
                list['userDelete'] = isChecked
            }
            if (name == "Edit") {
                list['userEdit'] = isChecked
            }

            setUserListChecked({
                ...userListChecked,
                [name]: isChecked
            });
        }


        else if (type === "polls") {
            if (name == "Read") {
                list['pollsRead'] = isChecked
            }
            if (name == "Manage") {
                list['pollsManage'] = isChecked
            }
            if (name == "Create") {
                list['pollsCreate'] = isChecked
            }
            if (name == "Edit") {
                list['pollsEdit'] = isChecked
            }

            setPollsChecked({
                ...pollsChecked,
                [name]: isChecked
            });

        }

        else if (type === "adminusermanagement") {
            if (name == "Read") {
                list['adminUserManagementRead'] = isChecked
            }
            if (name == "Manage") {
                list['adminUserManagementManage'] = isChecked
            }
            if (name == "Create") {
                list['adminUserManagementCreate'] = isChecked
            }
            if (name == "Edit") {
                list['adminUserManagementEdit'] = isChecked
            }
            if (name == "Delete") {
                list['adminUserManagementDelete'] = isChecked
            }
            setAdminUserManagementChecked({
                ...adminUserManagementChecked,
                [name]: isChecked
            });

        }
        else if (type === "inquiry") {
            if (name == "Read") {
                list['inquiryRead'] = isChecked
            }
            if (name == "Manage") {
                list['inquiryManage'] = isChecked
            }

            setInquiryChecked({
                ...inquiryChecked,
                [name]: isChecked,

            });

        }
        setAccessdata(list)
    };

    // for showing checkbox (Parth Roka)
    const renderCheckbox = (name, type) => {
        return (
            <label key={name}>
                <input
                    type="checkbox"
                    name={name}
                    checked={type === "dashboard" ? dashboardChecked[name] : type === "trips" ? tripsChecked[name] : type === "userlist" ? userListChecked[name] : type === "polls" ? pollsChecked[name] : type === "adminusermanagement" ? adminUserManagementChecked[name] : inquiryChecked[name]}
                    onChange={(event) => handleChecked(event, type)}
                />
                {type === "trips" ? "" : type === "userlist" ? "" : ""} {name}
            </label>
        );
    };
    const tripsCheckboxNames = Object.keys(tripsChecked);
    const userlistCheckboxNames = Object.keys(userListChecked);
    const adminusermanagemntCheckboxNames = Object.keys(adminUserManagementChecked);
    const pollsCheckboxNames = Object.keys(pollsChecked);
    const inquiryCheckboxNames = Object.keys(inquiryChecked);

    return (
        <>
            <Loader />
            <div className={toggleReducer == false ? "contents expanded" : "contents"} >
                <div className="crm">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-main">
                                    <h4 className="text-capitalize breadcrumb-title">{props22 ? "Edit User" : "Add User"} </h4>
                                    {/* <div class="breadcrumb-action justify-content-center flex-wrap">
                                    <div className=''> <ul class="atbd-breadcrumb nav">
                                        <li class="atbd-breadcrumb__item">

                                            <a href="#">
                                                Home
                                            </a>
                                            <span class="breadcrumb__seperator">
                                                <span class="la la-slash"></span>
                                            </span>


                                        </li>
                                        <li class="atbd-breadcrumb__item">
                                            <a href="#">Application Center</a>
                                            <span class="breadcrumb__seperator">
                                            </span>
                                        </li>

                                    </ul>
                                    </div>
                                    </div> */}
                                </div>

                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-lg-12">
                                <div className="card card-horizontal card-default card-md mb-4">
                                    <div className="card-header">

                                        <div className="card-body py-md-30">
                                            <div className="horizontal-form">
                                                <form className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group ">

                                                            <label htmlFor="inputName"
                                                                className=" col-form-label color-dark fs-14 fw-500 align-center">Name *</label>

                                                            <input type="text"
                                                                className="form-control ih-medium ip-light radius-xs b-light px-15"
                                                                autoComplete="off" placeholder="Enter Name" value={Name} onChange={(e) => handleName(e)} maxLength={30} />
                                                            <span style={{ "color": "#BE312E" }}>{NameError}</span>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group ">

                                                            <label htmlFor="inputName"
                                                                className=" col-form-label color-dark fs-14 fw-500 align-center">Email *</label>

                                                            <input type="text"
                                                                className="form-control ih-medium ip-light radius-xs b-light px-15"
                                                                autoComplete="off" placeholder="Enter Email" value={email} onChange={(e) => handleEmail(e.target.value.trim())} maxLength={320} disabled={props22 ? props22.email : ""} />
                                                            {emailError}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group ">

                                                            <label htmlFor="inputName"
                                                                className=" col-form-label color-dark fs-14 fw-500 align-center">Phone *</label>

                                                            <input type="text"
                                                                className="form-control ih-medium ip-light radius-xs b-light px-15"
                                                                name="Mobile" autoComplete="false" placeholder="Enter Phone No" value={Mobile} maxLength='10' onChange={(e) => handleMobile(e)} />
                                                            {MobileError && <span style={{ "color": "#BE312E" }}>Please enter phone no </span>}
                                                            {MobileError22 && <span style={{ "color": "#BE312E" }}>Please enter valid phone no</span>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Preferred Roles *</label>
                                                        <div className="form-group row align-items-center">
                                                            <div className="col-md-3">
                                                                <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Trips</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className='custom-role-labels'>
                                                                    {tripsCheckboxNames.map((name) => renderCheckbox(name, "trips"))}

                                                                    <label>
                                                                        <input
                                                                            className='mr-1'
                                                                            type="checkbox"
                                                                            checked={tripsCheckboxNames.every((name) => tripsChecked[name])}
                                                                            onChange={(event) => handleAllChecked(event, "trips")}
                                                                        />
                                                                        All
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row align-items-center">
                                                            <div className="col-md-3">
                                                                <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">User List</label>
                                                            </div>

                                                            <div className="col-md-9">
                                                                <div className='custom-role-labels'>
                                                                    {userlistCheckboxNames.map((name) => renderCheckbox(name, "userlist"))}

                                                                    <label>
                                                                        <input
                                                                            className='mr-1'
                                                                            type="checkbox"
                                                                            checked={userlistCheckboxNames.every((name) => userListChecked[name])}
                                                                            onChange={(event) => handleAllChecked(event, "userlist")}
                                                                        />
                                                                        All
                                                                    </label>
                                                                </div>


                                                            </div>

                                                        </div>

                                                        <div className="form-group row align-items-center">

                                                            <div className="col-md-3">

                                                                <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Polls Category</label>
                                                            </div>

                                                            <div className="col-md-9">
                                                                <div className='custom-role-labels'>
                                                                    {pollsCheckboxNames.map((name) => renderCheckbox(name, "polls"))}

                                                                    <label>
                                                                        <input
                                                                            className='mr-1'
                                                                            type="checkbox"
                                                                            checked={pollsCheckboxNames.every((name) => pollsChecked[name])}
                                                                            onChange={(event) => handleAllChecked(event, "polls")}
                                                                        />
                                                                        All
                                                                    </label>
                                                                </div>


                                                            </div>

                                                        </div>

                                                        <div className="form-group row align-items-center">

                                                            <div className="col-md-3">

                                                                <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Admin User Management</label>
                                                            </div>

                                                            <div className="col-md-9">
                                                                <div className='custom-role-labels'>
                                                                    {adminusermanagemntCheckboxNames.map((name) => renderCheckbox(name, "adminusermanagement"))}

                                                                    <label>
                                                                        <input
                                                                            className='mr-1'
                                                                            type="checkbox"
                                                                            checked={adminusermanagemntCheckboxNames.every((name) => adminUserManagementChecked[name])}
                                                                            onChange={(event) => handleAllChecked(event, "adminusermanagement")}
                                                                        />
                                                                        All
                                                                    </label>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="form-group row align-items-center">
                                                            <div className="col-md-3">
                                                                <label htmlFor="inputName" className=" col-form-label color-dark fs-14 fw-500 align-center">Inquiry</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className='custom-role-labels'>
                                                                    {inquiryCheckboxNames.map((name) => renderCheckbox(name, "inquiry"))}
                                                                    <label>
                                                                        <input
                                                                            className='mr-1'
                                                                            type="checkbox"
                                                                            checked={inquiryCheckboxNames.every((name) => inquiryChecked[name])}
                                                                            onChange={(event) => handleAllChecked(event, "inquiry")}
                                                                        />
                                                                        All
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {preferRoleError && <span style={{ "color": "#BE312E" }}>Please Enter Preferred Roles</span>}
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="centerd-btn m-20-auto d-block text-center mt-20">
                                                            <button type="button"
                                                                className="btn d-inline btn-primary btn-default btn-squared px-30 mx-10 save-btn" onClick={() => { allDeatils(); }}>Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <MainDashboard />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default AddAdmin;
