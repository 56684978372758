import React from "react";

// Added by Parth Roka
function Footer() {
    return (
        <>
            <footer className="footer-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <p>  © Our Travel {(new Date().getFullYear())}.</p>
                        </div>
                        <div className="col-sm-6">
                            <div className="text-right d-none d-sm-block">
                                Powered by  <a href="https://www.nichetechsolutions.com/" target="_blank">Nichetech.in</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}


export default Footer;